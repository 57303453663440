import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import VisitFailureChips from "./common/VisitFailureChips";
import PartiallyCollectedTableBins from "./common/PartiallyCollectedTableBins";
import { ModelErrors } from "sharedComponents";
import { isEmpty } from "lodash";
import { FeedbackDateSelect } from "./ClientFeedbackOnFailedVisitReportForm";
import NotesSection from "./common/NotesSection";

const VendorFeedbackOnFailedVisitReportForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [openReattemptDate, setOpenReattemptDate] = useState(false);
  const [reattemptDateSelect, setReattemptDateSelect] = useState();

  const {
    reportUuid,
    collectionFailureReasonSlug,
    deliveryFailureReasonSlug,
    deliveryResultSlug,
    collectionResultSlug,
  } = form;

  const {
    serviceName,
    deliveryFailureReasons,
    collectionFailureReasons,
    deliveryResults,
    collectionResults,
    nextVisits,
    inUseBinGroups,
    visitPlanName,
    allReports,
  } = context;

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const showCollectionReasonFailure = [
    "partial_failure",
    "total_failure",
  ].includes(collectionResultSlug);

  const showDeliveryReasonFailure = [
    "partial_failure",
    "total_failure",
  ].includes(deliveryResultSlug);

  const showPartialCollected =
    !isEmpty(inUseBinGroups) &&
    ["partial_failure"].includes(collectionResultSlug);

  const collectionHandler = (slug) =>
    onSetForm({
      collectionResultSlug: slug,
    });

  const deliveryHandler = (slug) =>
    onSetForm({
      deliveryResultSlug: slug,
    });

  const handleReattemptChange = (event) => {
    setReattemptDateSelect(event.target.value);
    onSetForm({
      reattemptDateProvided: event.target.value,
    });
  };
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        sx={{ "& .MuiTypography-root": { fontWeight: "bold" } }}
      >
        <Typography variant="h5">Vendor Feedback</Typography>
        <Typography color="grey" variant="h6">
          / {serviceName}
        </Typography>
      </Stack>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& > *": {
              flex: 1,
            },
          }}
        >
          <Box>
            <Typography>Failure Date</Typography>
            <FeedbackDateSelect
              allReports={allReports}
              selectedValue={reportUuid}
              onChange={(reportUuid) => {
                onSetForm({ reportUuid });
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography>Did the vendor confirmed re-attempt date?</Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <ToggleButtonGroup
                size="small"
                color="success"
                value={reattemptDateSelect}
                exclusive
                onChange={handleReattemptChange}
                sx={{
                  "& .MuiToggleButton-root": {
                    py: 0,
                    fontSize: "12px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      backgroundColor: "#007bff",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#0056b3",
                      },
                    },
                  },
                  "& .MuiToggleButtonGroup-grouped": {
                    borderRadius: 1,
                    mx: 0,
                  },
                }}
              >
                <ToggleButton value="true">Yes</ToggleButton>
                <ToggleButton value="false">No</ToggleButton>
              </ToggleButtonGroup>
              <Box
                sx={{
                  visibility:
                    reattemptDateSelect === "true" ? "inherit" : "hidden",
                }}
              >
                <ServiceCalendarDatePicker
                  open={openReattemptDate}
                  visitDays={visitDays}
                  setOpen={setOpenReattemptDate}
                  onChange={(newValue) => {
                    onSetForm({
                      reattemptDate: newValue.format("DD/MM/YYYY"),
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& > *": {
              flex: 1,
            },
          }}
        >
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste collected
            </Typography>
            <VisitFailureChips
              options={collectionResults}
              selectedSlug={collectionResultSlug}
              onChange={collectionHandler}
              failureReason={collectionFailureReasonSlug}
              failureReasons={collectionFailureReasons}
              showReasonFailure={showCollectionReasonFailure}
              onChangeSelect={(event) =>
                onSetForm({
                  collectionFailureReasonSlug: event.target.value,
                })
              }
            />
          </div>
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste delivered
            </Typography>
            <VisitFailureChips
              options={deliveryResults}
              selectedSlug={deliveryResultSlug}
              onChange={deliveryHandler}
              failureReason={deliveryFailureReasonSlug}
              failureReasons={deliveryFailureReasons}
              showReasonFailure={showDeliveryReasonFailure}
              onChangeSelect={(event) =>
                onSetForm({
                  deliveryFailureReasonSlug: event.target.value,
                })
              }
            />
          </div>
        </Grid>
        {showPartialCollected && (
          <PartiallyCollectedTableBins
            inUseBinGroups={inUseBinGroups}
            onSetForm={onSetForm}
          />
        )}
      </Grid>

      <ModelErrors errors={errors} />
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Feedback By"
        contactPlaceholder="Who reported the feedback?"
        dateLabel="Feedback on"
        dateValue={form?.reportedOn}
        onChange={(newValue) =>
          onSetForm({
            reportedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
    </div>
  );
};

export default VendorFeedbackOnFailedVisitReportForm;
