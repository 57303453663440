import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useState } from "react";
import "react-infinite-calendar/styles.css";
import { ModelErrors, ShowDate } from "sharedComponents";
import CollectionInstruction from "../CollectionInstruction";
import DeliveryInstruction from "../DeliveryInstruction";
import Debugger from "./Debugger";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";
import ThreeColumnFlexBox from "./common/ThreeColumnFlexBox";
import NotesSection from "./common/NotesSection";

interface FormProps {
  date: string;
  sourceContactId: number;
  notes: string;
  receivedOn: string;
}

interface ContextProps {
  serviceName: string;
  vendorLocationCode: string;
  visitPlanName: string;
  sourceIsUnknown: boolean;
  requestDeliveryUnits: any;
  requestCollectionUnits: any;
  requestCollectionCodes: string[];
  requestDeliveryCodes: string[];
  requestClearAll: boolean;
  nextVisits: any[];
  sourceContacts: any[];
}

interface ConfirmAdhocVisitFormProps {
  form: FormProps;
  context: ContextProps;
  errors: any;
  onSetForm: (form: any) => void;
}

const ConfirmAdhocVisitForm: React.FC<ConfirmAdhocVisitFormProps> = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const [open, setOpen] = useState(false);

  const {
    visitPlanName,
    serviceName,
    requestDeliveryUnits,
    requestDeliveryCodes,
    requestCollectionUnits,
    requestCollectionCodes,
    requestClearAll,
    nextVisits,
  } = context;

  const nextVisit = nextVisits[0];
  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {" "}
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm the Adhoc Visit Date
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ color: "#828B98", marginTop: 1 }}>
        Next Planned Visit: <ShowDate date={nextVisit?.date} />
        {nextVisit?.status && ` (${_.capitalize(nextVisit.status)})`}
      </Box>
      <ThreeColumnFlexBox>
        <Box>
          <label>Confirmed Visit Date</label>
          <ServiceCalendarDatePicker
            visitDays={visitDays}
            // minDate={dayjs(new Date())}
            open={open}
            setOpen={setOpen}
            onChange={(newValue) =>
              onSetForm({ date: newValue.format("DD/MM/YYYY") })
            }
          />
        </Box>
      </ThreeColumnFlexBox>
      <Box sx={{ mt: 1 }}>
        <CollectionInstruction
          {...context}
          collectionUnits={requestCollectionUnits}
          requestCollectionCodes={requestCollectionCodes}
          clearAll={requestClearAll}
          status={"confirmation"}
        />

        <DeliveryInstruction
          deliveryUnits={requestDeliveryUnits}
          requestDeliveryCodes={requestDeliveryCodes}
          status={"confirmation"}
          {...context}
        />
      </Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Confirmed by"
        contactPlaceholder="Who is confirming the visit?"
        dateLabel="Received on"
        dateValue={form?.receivedOn}
        onChange={(newValue) =>
          onSetForm({
            receivedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default ConfirmAdhocVisitForm;
