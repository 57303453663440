import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { flatMap, map, startCase } from "lodash";
import { useState } from "react";
import BinDot from "service/BinDot";
import { DeliveryTable } from "service/DeliveryInstruction";
import { formatDate, useBinFetch } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const StockDeliveryRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { binTypes, binGroups } = useBinFetch();
  const { cancelledOn, date, notes, deliveryUnits } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders = flatMap(stakeholderContacts, (x) => x);

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(request.name)}
        </Typography>
        <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
          on {formatDate(request?.requestedOn)} by{" "}
          {inputtedBy?.nameWithoutEmail}
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
          "& td.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {cancelledOn ? (
          <Typography variant="h6" sx={{ my: 1 }}>
            Stock Delivery Request Cancelled on {formatDate(cancelledOn)}
          </Typography>
        ) : date ? (
          <Typography variant="h6" sx={{ my: 1 }}>
            Confirmed by vendor on {formatDate(date)}
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ my: 1 }}>
            Stock Delivery Requested on {formatDate(request?.requestedOn)}
          </Typography>
        )}

        {deliveryUnits && <DeliveryTable rows={deliveryRows} label={false} />}
        {notes && (
          <Typography variant="h6" sx={{ my: 1 }}>
            {notes}
          </Typography>
        )}
      </Collapse>
    </>
  );
};

export default StockDeliveryRequestLog;
