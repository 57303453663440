import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Box, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useGetLocationServiceVendorQuery } from "api/services";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ListView from "service-calendar/components/ListView";
import Calendar from "service/Calendar";
import LocationServicesVendorBox from "service/LocationServicesVendorBox";
import VisitDetails from "service/VisitDetails";
import NewMessageActionForm from "service/forms/NewMessageActionForm";
import MainTabs, {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "service/report-request-box/MainTabs";
import ChannelFooter from "service/ticket/channel-footer/ChannelFooter";
import ResponseForm from "service/ticket/channel-footer/form/ResponseForm";
import { useDrawer } from "service/ticket/channel-footer/useDrawer";
import ChannelHeader from "service/ticket/channel-header/ChannelHeader";
import SortedReplies from "service/ticket/response-card/SortedReplies";
import { formatDate, updateQueryStringParameter } from "sharedUtils";
import AppContext from "./AppContext";
import { styles } from "./AppStyles";
import {
  getAllServices,
  getLocationId,
  getVendorId,
  useTicket,
  useTicketReplies,
} from "./selectors";
import { useTypedSelector } from "./app/store";
import { filter } from "lodash";
import { extractIdFromUrl } from "service/ticket/utils";

const App = () => {
  const ticket = useTicket();
  const locationId = getLocationId();
  const vendorId = getVendorId();
  const replies = useTicketReplies();
  const allServices = getAllServices();
  const activeServices = filter(
    allServices,
    (service) => !["terminated", "suspended"].includes(service?.status),
  );
  const servicesToResume = filter(allServices, (service) =>
    ["requested_to_terminate", "requested_to_suspend", "terminated"].includes(
      service?.status,
    ),
  );

  const {
    data: locationServiceVendors,
    isLoading: isLoadLocationServiceVendor,
  } = useGetLocationServiceVendorQuery({ locationId, vendorId });

  const [openFormUrl, setOpenFormUrl] = useState<string>("");
  const [openFormId, setOpenFormId] = useState(null);
  const [showFooterButtons, setShowFooterButtons] = useState(true);
  const [showDrawer, setShowDrawer] = useState(true);
  const [serviceBoxTab, setServiceBoxTab] = useState(0);
  const [visitDate, onClickVisitDate] = useState();
  const [visitTab, setVisitTab] = useState(undefined);
  const [primaryTab, setPrimaryTab] = useState(0);
  const [calendarView, setCalendarView] = useState("calendar");
  // TODO: Remove selectedContainer after we remove form cards
  const [selectedContainer, setSelectedContainer] = useState(new Set());
  const [showOverview, setShowOverview] = useState(false);
  const [heightInVh, setHeightInVh] = useState(0);
  const [formType, setFormType] = useState("all");
  const [cardTab, setCardTab] = useState("request");
  const [showTaggingForm, setShowTaggingForm] = useState(false);
  const [highlightedId, setHighlightedId] = useState();

  const serviceIdMatch = openFormUrl?.match(/\/service_actions\/(\d+)/);
  const initialValue = serviceIdMatch ? serviceIdMatch[1] : "";
  const [selectedService, setSelectedService] = useState(initialValue);

  useEffect(() => {
    if (openFormUrl) {
      const id = extractIdFromUrl(openFormUrl);
      if (id) {
        if (activeServices.length === 1 || servicesToResume.length === 1) {
          setSelectedService(id);
        }
      }
    }
  }, [openFormUrl, activeServices, servicesToResume]);

  const {
    isDrawerOpen,
    closeDrawer,
    activeChannel,
    toggleDrawerAndSetChannel,
    setIsDrawerOpen,
    setActiveChannel,
  } = useDrawer();

  const onCloseForm = () => {
    setOpenFormUrl(null);
    closeDrawer();
    setActiveChannel(null);
    setShowFooterButtons(true);
    setSelectedService("");
  };

  const onSelectAction = (_x, { path }) => {
    setIsDrawerOpen(true);
    setActiveChannel(null);
    setShowFooterButtons(false);

    if (activeServices.length === 1) {
      const serviceId = activeServices[0].id;

      setOpenFormUrl((_prevOpenFormUrl) =>
        updateQueryStringParameter(path, "ticket_id", ticket.id).replace(
          /(\/service_actions\/)\d+/,
          `$1${serviceId}`,
        ),
      );
    } else {
      setOpenFormUrl(updateQueryStringParameter(path, "ticket_id", ticket.id));
    }
  };

  const handleToggleButton = (channel) => {
    setOpenFormUrl(null);
    setOpenFormId(null);
    toggleDrawerAndSetChannel(channel);
  };

  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setOpenFormUrl(
      openFormUrl.replace(/(\/service_actions\/)\d+/, `$1${serviceId}`),
    );
    setSelectedService(serviceId);
  };

  const handleServiceBoxTab = (_event, newValue) => {
    setServiceBoxTab(newValue);
    setVisitTab(undefined);
  };

  const handlePrimaryTab = (_event, newValue) => {
    setPrimaryTab(newValue);
  };

  const onDayClick = (d) => {
    onClickVisitDate(d);
    setVisitTab(formatDate(d));
    setServiceBoxTab(1);
  };

  const currentForm = openFormUrl ? (
    <NewMessageActionForm
      key={openFormUrl}
      formUrl={openFormUrl}
      onClose={onCloseForm}
      stakeholderContacts={ticket?.stakeholderContacts}
      setOpenFormUrl={setOpenFormUrl}
      selectedService={selectedService}
      handleServiceChange={handleServiceChange}
    />
  ) : (
    <ResponseForm
      stakeholderContacts={ticket?.stakeholderContacts}
      channel={activeChannel}
      cannedResponsesMap={ticket?.cannedResponsesMap}
      closeDrawer={closeDrawer}
    />
  );
  const handleCalendarView = (
    _event: React.MouseEvent<HTMLElement>,
    newCalendarView: string | null,
  ) => {
    if (calendarView !== newCalendarView && newCalendarView !== null) {
      setCalendarView(newCalendarView);
      if (newCalendarView === "list") {
        setServiceBoxTab(0);
        setVisitTab(undefined);
      }
    }
  };

  const onClickShowOverview = () => {
    setShowOverview(!showOverview);
    const el = document.getElementById("service-box");

    // Ensure the element exists
    if (el) {
      // Get the viewport height
      const vh = window.innerHeight || document.documentElement.clientHeight;

      // Calculate the height of the element in vh
      const newHeight = (el.offsetHeight / vh) * 100;
      setHeightInVh(newHeight);
    } else {
      console.log("Element not found.");
      // Handle the case where the element doesn't exist
      return null;
    }
  };

  useEffect(() => {
    const el = document.getElementById("service-box");

    if (!el) return;

    const calculateAndSetHeight = () => {
      if (showOverview) {
        const vh = window.innerHeight || document.documentElement.clientHeight;
        const newHeightInVh = (el.offsetHeight / vh) * 100;
        setHeightInVh(newHeightInVh);
      }
    };

    const observer = new ResizeObserver((entries) => {
      for (let _entry of entries) {
        calculateAndSetHeight();
      }
    });

    observer.observe(el);

    return () => observer.disconnect();
  }, [showOverview]);

  return (
    <AppContext.Provider
      value={{
        onSelectAction,
        onClickVisitDate,
        setServiceBoxTab,
        setVisitTab,
        selectedContainer,
        setSelectedContainer,
        formType,
        setFormType,
        cardTab,
        setCardTab,
        activeServices,
        servicesToResume,
        showTaggingForm,
        setShowTaggingForm,
        highlightedId,
        setHighlightedId,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiGrid-item:first-of-type": {
            pl: 3,
          },
        }}
      >
        <Grid
          item
          xs={8}
          sx={{
            pt: 0,
            position: "relative",
          }}
        >
          <StyledTabs
            value={primaryTab}
            onChange={handlePrimaryTab}
            sx={{
              height: "fit-content",
              position: "sticky",
              top: showOverview ? 0 : 80,
              paddingTop: "0 !important",
              zIndex: 1,
            }}
          >
            <StyledTab label="Ticket" />
            <StyledTab label="Visit Activity" />
          </StyledTabs>

          <TabPanel value={primaryTab} index={0}>
            <Box sx={styles.container}>
              <ChannelHeader
                showOverview={showOverview}
                onClickShowOverview={onClickShowOverview}
                primaryTab={primaryTab}
              />

              <Box
                className="replies-container"
                sx={{
                  ...(showOverview && {
                    height: heightInVh > 50 ? `${heightInVh}vh` : "50vh",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#656567 #2d323a",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#2d323a",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#656567",
                      borderRadius: "4px",
                    },
                  }),
                }}
              >
                <SortedReplies
                  replies={replies}
                  setIsDrawerOpen={setIsDrawerOpen}
                  setActiveChannel={setActiveChannel}
                  openFormId={openFormId}
                  setOpenFormId={setOpenFormId}
                  setShowFooterButtons={setShowFooterButtons}
                  onCloseForm={onCloseForm}
                  setShowDrawer={setShowDrawer}
                />
              </Box>

              <ChannelFooter
                openFormUrl={openFormUrl}
                isDrawerOpen={isDrawerOpen}
                activeChannel={activeChannel}
                currentForm={currentForm}
                closeDrawer={closeDrawer}
                handleToggleButton={handleToggleButton}
                onSelectAction={onSelectAction}
                showFooterButtons={showFooterButtons}
                showDrawer={showDrawer}
              />
            </Box>
          </TabPanel>
          <TabPanel value={primaryTab} index={1} sx={{ position: "relative" }}>
            <ToggleButtonGroup
              value={calendarView}
              exclusive
              onChange={handleCalendarView}
              sx={{
                position: "absolute",
                right: 0,
                background: "#2C3237",
                "& *": { color: "#fff" },
              }}
            >
              <ToggleButton value="calendar">
                <CalendarMonthIcon />
              </ToggleButton>
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {calendarView === "calendar" && (
              <Calendar
                calendarApi={useTypedSelector((state) => state.calendarApi)}
                onDayClick={onDayClick}
              />
            )}
            {calendarView === "list" && (
              <div className="anenta-calendar">
                <ListView
                  calendarApi={useTypedSelector((state) => state.calendarApi)}
                />
              </div>
            )}
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={4}
          id="service-box"
          sx={{
            height: "fit-content",
            position: "sticky",
            top: 80,
            marginTop: 2,
            paddingTop: "0 !important",
          }}
        >
          {!isLoadLocationServiceVendor && (
            <>
              <StyledTabs value={serviceBoxTab} onChange={handleServiceBoxTab}>
                <StyledTab label="Services" />
                {visitTab && <StyledTab label={visitTab} />}
              </StyledTabs>
              <TabPanel value={serviceBoxTab} index={0}>
                {locationServiceVendors && vendorId && (
                  <LocationServicesVendorBox
                    {...locationServiceVendors[vendorId]}
                    onSelectAction={onSelectAction}
                  />
                )}
              </TabPanel>
              <TabPanel value={serviceBoxTab} index={1}>
                <VisitDetails
                  selectedDay={dayjs(visitDate)}
                  locationId={locationId}
                  vendorId={vendorId}
                  onBack={() => undefined}
                  locationServiceVendors={locationServiceVendors}
                />
              </TabPanel>
              <MainTabs />
            </>
          )}
        </Grid>
      </Grid>
    </AppContext.Provider>
  );
};

export default App;
