import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TicketReply } from "../../../api/tickets";
import { CustomOutlinedButton } from "../actions-menu/ActionMenuButton";
import { styles } from "./ReplyCardStyles";

const ProcessedBox = ({ processedByName, handleUpdateTicketReply }) => {
  return (
    <Box sx={styles.processed}>
      <>
        <Box sx={styles.flexCenterGap}>
          <Typography sx={styles.successText}>
            <DoneIcon color="success" fontSize="large" />
          </Typography>
          <Box sx={styles.successMain}>Processed</Box>
          <Box>by {processedByName}</Box>
        </Box>
        <CustomOutlinedButton
          size="small"
          onClick={() => handleUpdateTicketReply(null, false)}
        >
          Un-Process
        </CustomOutlinedButton>
      </>
    </Box>
  );
};

const NotProcessedBox = ({ handleUpdateTicketReply }) => (
  <Box sx={styles.notProcessedContainer}>
    <Box sx={styles.notProcessedBox}>
      <Typography sx={styles.errorText}>
        <CloseIcon fontSize="large" color="error" />
      </Typography>
      <Box sx={styles.errorMain}>Not Processed</Box>
    </Box>
    <Box sx={styles.buttonsBox}>
      <Button
        variant="contained"
        color="success"
        size="small"
        sx={styles.successButton}
        onClick={() => handleUpdateTicketReply(true, false)}
      >
        Process and do NOT require a response
      </Button>
      <Button
        size="small"
        variant="contained"
        color="error"
        sx={styles.errorButton}
        onClick={() => handleUpdateTicketReply(true, true)}
      >
        Process and still require a response
      </Button>
    </Box>
  </Box>
);

const ReplyCardContent = ({
  reply,
  handleUpdateTicketReply,
  isLoading,
}: {
  reply: TicketReply;
  handleUpdateTicketReply: (newProcess: boolean, newResponse: string) => void;
  isLoading: boolean;
}) => {
  const { message, processedStatus, processed, processedByName, attachments } =
    reply;

  return (
    <CardContent sx={styles.cardContent}>
      <Box>{message}</Box>

      {attachments && attachments.length > 0 && (
        <Box sx={{ marginTop: 1 }}>
          <Divider
            sx={{
              left: 0,
              right: 0,
              top: 0,
              background: "#fff",
            }}
          />
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle1" color="info">
              Attachments:
            </Typography>
            {attachments.map((attachment) => (
              <Box key={attachment.url}>
                <a
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.name}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {/* TODO: Remove this completely when processed is not used */}
      {/* <Box sx={styles.processContainer}> */}
      {/*   {isLoading ? ( */}
      {/*     <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}> */}
      {/*       <CircularProgress /> */}
      {/*     </Box> */}
      {/*   ) : processedStatus === "Processed" ? ( */}
      {/*     <ProcessedBox */}
      {/*       processedByName={processedByName} */}
      {/*       handleUpdateTicketReply={handleUpdateTicketReply} */}
      {/*     /> */}
      {/*   ) : ( */}
      {/*     processedStatus !== "Default" && */}
      {/*     !processed && ( */}
      {/*       <NotProcessedBox */}
      {/*         handleUpdateTicketReply={handleUpdateTicketReply} */}
      {/*       /> */}
      {/*     ) */}
      {/*   )} */}
      {/* </Box> */}
    </CardContent>
  );
};

export default ReplyCardContent;
