import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Tooltip, Typography } from "@mui/material";
import { TicketReply } from "../../../api/tickets";
import { Channel } from "../types";
import { getBorderColor, styles } from "./ReplyCardStyles";
import { formatDate } from "sharedUtils";

const ReplyCardHeader = ({ reply }: { reply: TicketReply }) => {
  const { sentByName, channel } = reply;
  return (
    <>
      <Box
        sx={{
          color: "#AAB2BD",
          borderBottom: `1px solid ${getBorderColor(reply.stakeholderName)}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "8px 16px",
          "& *": {
            fontSize: "11px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            title={formatDate(reply.createdAt)}
            placement="top"
            arrow
            slotProps={{
              tooltip: { style: styles.tooltipStyles.tooltip },
              arrow: { style: styles.tooltipStyles.arrow },
            }}
          >
            <AccessTimeIcon fontSize="large" />
          </Tooltip>
          {channel === Channel.Agent && (
            <DoneIcon color="success" fontSize="large" />
          )}
          <Typography sx={{ color: "#AAB2BD" }}>
            {reply.createdAtInWords} ago{" "}
            {reply?.sentToName && ` | Sent to ${reply?.sentToName}`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography sx={{ color: "#AAB2BD" }}>
            Sent by {sentByName}
          </Typography>
          <Typography sx={{ color: "grey" }}>
            {reply.stakeholderName}
          </Typography>
        </Box>
      </Box>
      {reply?.reminder && (
        <Typography
          sx={{
            p: 2,
            py: 1,
            fontSize: "12px",
            fontWeight: "bold",
            color: "#8CC152",
            borderBottom: "1px solid #545454",
          }}
        >
          Reminder set for {formatDate(reply.reminder)}
        </Typography>
      )}
    </>
  );
};

export default ReplyCardHeader;
