import PropTypes from "prop-types";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import dotProp from "dot-prop-immutable";
import { sanatizeDotProp, useBinFetch } from "sharedUtils";
import { Box, Stack, Typography } from "@mui/material";
import _, { toArray } from "lodash";
import DeliveryUnitsTable from "./common/DeliveryUnitsTable";
import { useState } from "react";
import { BinTypeSelectorPopover } from "./common/QuantitySelector";
import NotesSection from "./common/NotesSection";
import dayjs from "dayjs";

var d = dotProp;

const RequestStockDeliveryForm = ({ form, context, errors, onSetForm }) => {
  const { deliveryUnits } = form;

  const [anchorElemAddStock, setAnchorElemAddStock] = useState(null);

  const { visitPlanName, serviceName, availableBinTypeCodes } = context;

  const {
    binTypes,
    binGroups,
    isLoading: binsloading,
  } = useBinFetch(availableBinTypeCodes);

  if (binsloading) {
    return null;
  }

  const availableDeliveryOptions = _.pick(
    binTypes,
    _.difference(availableBinTypeCodes, _.map(deliveryUnits, "serviceCode")),
  );

  const updateDeliveryQuantity = (quantity, serviceCode) => {
    const newUnits = d.merge(deliveryUnits, sanatizeDotProp(serviceCode), {
      quantity,
    });
    onSetForm({ deliveryUnits: newUnits });
  };

  const removeDeliveryUnit = (serviceCode) => {
    // Using _.omit to create a new object without the specified serviceCode
    const updatedDeliveryUnits = _.omit(deliveryUnits, serviceCode);
    onSetForm({ deliveryUnits: updatedDeliveryUnits });
  };

  const updateDeliveryUnitHandler = (serviceCode) => {
    const newSpecs = d.merge(deliveryUnits, sanatizeDotProp(serviceCode), {
      quantity: 0,
      serviceCode,
    });

    onSetForm({ deliveryUnits: newSpecs });
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Delivery of Stock next {visitPlanName} visit{" "}
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Box mt={2}>
        <DeliveryUnitsTable
          headerDetailsHandler={(e) => setAnchorElemAddStock(e.currentTarget)}
          deliveryUnits={deliveryUnits}
          binTypes={binTypes}
          binGroups={binGroups}
          updateDeliveryQuantity={updateDeliveryQuantity}
          removeUnit={removeDeliveryUnit}
          availableDeliveryOptions={toArray(availableDeliveryOptions)}
        />
        {toArray(availableDeliveryOptions).length > 0 && (
          <BinTypeSelectorPopover
            anchorEl={anchorElemAddStock}
            setAnchorEl={setAnchorElemAddStock}
            options={toArray(availableDeliveryOptions)}
            addBinHandler={updateDeliveryUnitHandler}
          />
        )}
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who made the request?"
          contactLabel="Requested by"
          dateLabel="Requested on"
          dateValue={form?.requestedOn}
          onChange={(newValue) =>
            onSetForm({
              requestedOn: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </Box>
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

RequestStockDeliveryForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    serviceUuid: PropTypes.string.isRequired,
    visitPlanName: PropTypes.string.isRequired,
    availableBinTypeCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestStockDeliveryForm;
