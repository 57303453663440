import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { map } from "lodash";
import BinDot from "service/BinDot";
import { ModelErrors } from "sharedComponents";
import { useBinFetch } from "sharedUtils";
import CustomTable from "./common/CustomTable";
import "./styles.scss";
import NotesSection from "./common/NotesSection";

const CancelStockDeliveryForm = ({ form, context, errors, onSetForm }) => {
  const {
    visitPlanName,
    serviceName,
    requestDeliveryUnits,
    requestDeliveryCodes,
  } = context;

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const rows = map(requestDeliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={binType?.serviceCode}>
        <TableCell>
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{binType?.serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {" "}
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel the Stock Delivery Request for the Next Visit
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <CustomTable rows={rows} />
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactLabel="Cancelled By"
          contactPlaceholder="Who requested the cancellation?"
          dateLabel="Cancelled on"
          dateValue={form?.cancelledOn}
          onChange={(newValue) =>
            onSetForm({
              cancelledOn: newValue.format("DD/MM/YYYY"),
            })
          }
        />
        <ModelErrors errors={errors} />
      </Box>
    </div>
  );
};

export default CancelStockDeliveryForm;
