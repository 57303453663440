import { useState } from "react";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import NotesSection from "./common/NotesSection";

const ConfirmFinalCollectionForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  const { nextVisits, serviceName, visitPlanName } = context;

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm Final Visit
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Box sx={{ my: 1 }}>
        <label>Final visit Planned for</label>
        <ServiceCalendarDatePicker
          visitDays={visitDays}
          open={open}
          setOpen={setOpen}
          onChange={(newValue) => {
            onSetForm({
              date: newValue.format("DD/MM/YYYY"),
            });
          }}
        />
      </Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Confirmed by"
        contactPlaceholder="Who confirmed the date"
        dateLabel="Received on"
        dateValue={form?.receivedOn}
        onChange={(newValue) =>
          onSetForm({
            receivedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </div>
  );
};

export default ConfirmFinalCollectionForm;
