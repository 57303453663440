import { Autocomplete, TextField } from "@mui/material";
import { groupBy, map, startCase } from "lodash";

const ContactSelect = ({
  options,
  onChange,
  selectedValue,
  label,
  ...props
}) => {
  const selectedOption = options.find((option) => option.id === selectedValue);

  const handleChange = (_, newValue) => {
    if (newValue) {
      onChange({
        sourceContactId: newValue.id,
        sourceStakeholderId: newValue.stakeholderId,
      });
    } else {
      onChange({
        sourceContactId: null,
        sourceStakeholderId: null,
      });
    }
  };

  const optionsWithGroup = map(
    groupBy(options, "stakeholderName"),
    (value, key) => ({
      groupTitle: startCase(key),
      options: value,
    })
  );

  const autocompleteOptions = map(optionsWithGroup, "options").flat();

  return (
    <Autocomplete
      {...props}
      value={selectedOption}
      onChange={handleChange}
      options={autocompleteOptions}
      groupBy={(option) => startCase(option.stakeholderName)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" size="small" />
      )}
      renderOption={(props, option) => (
        <li style={{ fontSize: "12px" }} {...props}>
          {option.name}
        </li>
      )}
      sx={{
        flex: 1,
        "& .MuiInputBase-root": { fontSize: "12px" },
      }}
    />
  );
};

export default ContactSelect;
