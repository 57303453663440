import { Chip, MenuItem, Select, Stack, alpha } from "@mui/material";
import _ from "lodash";
import { FC } from "react";
import { colorForSlug } from "service/report-request-box/ReportVisitFailureCard";

interface VisitFailureChipsProps {
  options: any;
  selectedSlug: string;
  onChange: (slug: string) => void;
  showReasonFailure: boolean;
  failureReason?: string;
  failureReasons?: any;
  onChangeSelect?: (event: any) => void;
}

const VisitFailureChips: FC<VisitFailureChipsProps> = ({
  options,
  selectedSlug,
  onChange,
  failureReason,
  onChangeSelect,
  failureReasons,
  showReasonFailure,
}) => {
  const orderedOptions = _.orderBy(
    _.filter(options, (item) => item.slug !== "not_applicable"),
    [(item) => chipOrder[item.slug]],
    ["asc"]
  );
  return (
    <Stack direction="row" spacing={1}>
      {orderedOptions?.length > 0 &&
        orderedOptions.map((item, index) => (
          <Chip
            key={index}
            variant={selectedSlug === item.slug ? "filled" : "outlined"}
            label={getChipName(item.slug)}
            onClick={() => onChange(item.slug)}
            sx={{
              minWidth: "53px",
              fontSize: "12px",
              backgroundColor:
                selectedSlug === item.slug
                  ? colorForSlug[item.slug]
                  : undefined,
              color:
                selectedSlug === item.slug ? "#fff" : colorForSlug[item.slug],
              "&:hover": {
                backgroundColor: alpha(colorForSlug[item.slug], 0.8),
              },
              border:
                selectedSlug !== item.slug
                  ? `1px solid ${colorForSlug[item.slug]}`
                  : undefined,
            }}
          />
        ))}
      {showReasonFailure && (
        <Select
          size="small"
          displayEmpty
          value={failureReason}
          onChange={onChangeSelect}
        >
          <MenuItem value={null} disabled>
            Reason for failure
          </MenuItem>
          {failureReasons.map((reason) => (
            <MenuItem
              key={reason.slug}
              value={reason.slug}
              sx={{ fontSize: "12px" }}
            >
              {reason.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Stack>
  );
};

const getChipColor = (slug) => {
  switch (slug) {
    case "total_failure":
      return "error";
    case "partial_failure":
      return "warning";
    case "success":
      return "success";
    default:
      return "default";
  }
};

const getChipName = (slug) => {
  switch (slug) {
    case "total_failure":
      return "Nothing";
    case "partial_failure":
      return "Partial";
    case "success":
      return "All";
    default:
      return slug;
  }
};

const chipOrder = {
  success: 3,
  partial_failure: 2,
  total_failure: 1,
  not_applicable: 4,
};

export default VisitFailureChips;
