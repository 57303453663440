import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { colorForSlug } from "service/report-request-box/ReportVisitFailureCard";
import ReportVisitFailureCardTable from "service/report-request-box/ReportVisitFailureCardTable";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const FailedVisitReportLog = ({ request: report }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { notes } = report?.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders = flatMap(stakeholderContacts, (x) => x);

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === report?.metadata?.inputtedById,
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(report?.name)}
        </Typography>
        <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
          on {formatDate(report?.requestedOn)} by {inputtedBy?.nameWithoutEmail}
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          mb: 1,
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
          "& td.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Visit failed on{" "}
          <span style={{ color: colorForSlug.total_failure }}>
            {formatDate(report?.date)}
          </span>
        </Typography>
        <ReportVisitFailureCardTable report={report.data} />

        {notes && <Typography variant="h6">{notes}</Typography>}
      </Collapse>
    </>
  );
};

export default FailedVisitReportLog;
