import { api } from "./apiConfig";
import { getCurrentChannel } from "./utils";

// Ticket interfaces
export interface Ticket {
  id: number;
  subject: string;
  organisationResponseExpected: boolean;
  vendorResponseExpected: boolean;
  submitPath: string;
  updateTicketPath: string;
  cannedResponsesMap: {
    organisation: CannedResponse[];
    agent: CannedResponse[];
    vendor: CannedResponse[];
  };
  defaultContactMap: DefaultContactMap;
  stakeholderContacts: {
    organisation: StakeholderContacts;
    agent: StakeholderContacts;
    vendor: StakeholderContacts;
  };
  agentsMap: Record<number, Agent>;
  assignedAgent: Agent;
}

export interface Agent {
  id: string;
  name: string;
}

interface CannedResponse {
  id: number;
  name: string;
  authorised_at: null | string;
  authorised_by_id: null | number;
  description: string;
  copy: string;
  stakeholder_id: number;
  created_at: string;
  updated_at: string;
  snippet: boolean;
}

interface Contact {
  id: number;
  displayName: string;
}

export type StakeholderContacts = Record<string, Contact>;

interface DefaultContactMap {
  organisation: string;
  agent: null | string;
  vendor: string;
}

interface Attachment {
  url: string;
  name: string;
}

// Ticket Reply
export interface TicketReply {
  id: number;
  channel: string;
  createdAt: string;
  createdAtInWords: string;
  htmlSafeDisplayMessage: string;
  message: string;
  processed: boolean;
  processedStatus: string;
  sentByName: string;
  sentToName: string | null;
  stakeholderId: number;
  stakeholderName: string;
  test: string;
  url: string;
  attachments: Attachment[];
  tags: string[];
  reminder: boolean | string;
}

type TicketReplies = TicketReply[];

interface TicketResponse {
  channel: string;
  to_contact_id: number;
  message: string;
  clear_reminder: number;
  new_reminder_on: string;
  assigned_agent_id: number;
  response_expected: boolean;
}

// Ticket API Slice
export const ticketApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTicket: build.query<Ticket, string>({
      query: (ticketId) => ({ url: `/admin/tickets/${ticketId}/app` }),
      providesTags: ["Ticket"],
    }),
    updateTicket: build.mutation({
      query: ({ url, channel, expectedResponse }) => ({
        url: url,
        method: "PATCH",
        body: {
          ticket: {
            [getCurrentChannel(channel)]: expectedResponse,
          },
        },
      }),
      invalidatesTags: ["Ticket"],
    }),
  }),
});

export const { useGetTicketQuery, useUpdateTicketMutation } = ticketApi;
export const {
  endpoints: { getTicket },
} = ticketApi;

// Ticket Replies API Slice
export const ticketRepliesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTicketReplies: build.query<TicketReplies, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/ticket_replies`,
      }),
      transformResponse: (response: any) => response.replies as TicketReplies,
      providesTags: ["TicketReplies"],
    }),
    editTicketReply: build.mutation({
      query: ({ url, processed, channel, response }) => {
        const key = getCurrentChannel(channel);
        const body = {
          processed,
          // if processed exist, add ticket object
          ...(processed && { ticket: { [key]: response } }),
        };
        return {
          url,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ["Ticket", "TicketReplies"],
    }),
    addTicketReply: build.mutation<TicketResponse, any>({
      query: ({
        url,
        channel,
        message,
        responseExpected,
        blobFiles,
        originalFileNames,
        assignedAgentId,
        data,
      }) => {
        const formData = new FormData();

        // Add nested 'ticket_response' object fields
        formData.append("ticket_response[channel]", channel);
        formData.append(
          "ticket_response[to_contact_id]",
          data?.to_contact_id || "",
        );
        formData.append("ticket_response[message]", message || "");
        formData.append(
          "ticket_response[clear_reminder]",
          data?.clear_reminder || "0",
        );
        formData.append(
          "ticket_response[new_reminder_on]",
          data?.new_reminder_on || "",
        );
        formData.append(
          "ticket_response[assigned_agent_id]",
          assignedAgentId || "152",
        );
        formData.append(
          "ticket_response[response_expected]",
          responseExpected.toString(),
        );

        // Add attachments
        if (blobFiles && blobFiles.length > 0) {
          blobFiles.forEach((blobFile, index) => {
            const originalFileName = originalFileNames[index];
            formData.append(
              `ticket_response[attachments_attributes][${index}][file]`,
              blobFile,
              originalFileName,
            );
            formData.append(
              `ticket_response[attachments_attributes][${index}][_destroy]`,
              "false",
            );
          });
        }

        // Add ticket_id at the root level
        formData.append("ticket_id", "2324");

        return {
          url: url,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Ticket", "TicketReplies"],
    }),
    getFailedVisitReports: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/failed_visit_reports`,
      }),
      providesTags: ["FailedVisitReport"],
    }),
    getAdhocVisitRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/adhoc_visit_requests`,
      }),
      providesTags: ["AdhocVisitRequests"],
    }),
    getAdhocVisitRequestsByLocationAndVendor: build.query<any, any>({
      query: (queryObject) => ({
        url: `/admin/locations/${queryObject.locationId}/vendor/${queryObject.vendorId}/adhoc_visit_requests`,
      }),
      providesTags: ["AdhocVisitRequests"],
    }),
    getStockDeliveryRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/stock_delivery_requests`,
      }),
      providesTags: ["StockDeliveryRequests"],
    }),
    getRemediationCases: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/remediation_cases`,
      }),
      providesTags: ["RemediationCases"],
    }),
    getServiceSuspensionRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_suspension_requests`,
      }),
      providesTags: ["ServiceSuspensionRequests"],
    }),
    getServiceTerminationRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_termination_requests`,
      }),
      providesTags: ["ServiceTerminationRequests"],
    }),
    getServiceResumptionRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/service_resumption_requests`,
      }),
      providesTags: ["ServiceResumptionRequests"],
    }),
    getSpecChangeRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/spec_change_requests`,
      }),
      providesTags: ["SpecChangeRequests"],
    }),
    getVisitFrequencyChangeRequests: build.query<any, string>({
      query: (ticketId: string) => ({
        url: `/admin/tickets/${ticketId}/visit_frequency_change_requests`,
      }),
      providesTags: ["VisitFrequencyChangeRequests"],
    }),
  }),
});

export const {
  useGetTicketRepliesQuery,
  useEditTicketReplyMutation,
  useAddTicketReplyMutation,
  useGetFailedVisitReportsQuery,
  useGetAdhocVisitRequestsQuery,
  useGetAdhocVisitRequestsByLocationAndVendorQuery,
  useGetStockDeliveryRequestsQuery,
  useGetRemediationCasesQuery,
  useGetServiceSuspensionRequestsQuery,
  useGetServiceTerminationRequestsQuery,
  useGetServiceResumptionRequestsQuery,
  useGetSpecChangeRequestsQuery,
  useGetVisitFrequencyChangeRequestsQuery,
} = ticketRepliesApi;

export const {
  endpoints: { getTicketReplies, getFailedVisitReports },
} = ticketRepliesApi;
