import { Box, Grid } from "@mui/material";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import NewMessageActionForm from "service/forms/NewMessageActionForm";
import { TaggingForm } from "service/forms/TaggingForm";
import { concatId } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import { extractIdFromUrl } from "../utils";
import ReplyCard from "./ReplyCard";

const SortedReplies = ({
  replies,
  setIsDrawerOpen,
  setActiveChannel,
  openFormId,
  setOpenFormId,
  setShowFooterButtons,
  onCloseForm,
  setShowDrawer,
}) => {
  const {
    activeServices,
    servicesToResume,
    setShowTaggingForm,
    highlightedId,
  } = useContext(AppContext);

  const [openFormUrl, setOpenFormUrl] = useState<string>("");
  const [selectedService, setSelectedService] = useState("");

  useEffect(() => {
    if (openFormUrl) {
      const id = extractIdFromUrl(openFormUrl);
      if (id) {
        if (activeServices.length === 1 || servicesToResume.length === 1) {
          setSelectedService(id);
        }
      }
    }
  }, [openFormUrl, activeServices, servicesToResume]);

  const handleServiceChange = (event) => {
    const serviceId = event.target.value;
    setOpenFormUrl(
      openFormUrl.replace(/(\/service_actions\/)\d+/, `$1${serviceId}`),
    );
    setSelectedService(serviceId);
  };

  const sortedReplies = useMemo(() => {
    return _(replies)
      .groupBy("createdAt")
      .toPairs()
      .sortBy((kvArray) => kvArray[0])
      .map((kvArray) => {
        return _.sortBy(kvArray[1], [
          (reply) => (reply.channel === "vendor" ? 1 : -1),
        ]);
      })
      .value();
  }, [replies]);

  const toggleOpenForm = (id) => {
    setIsDrawerOpen(false);
    setActiveChannel(null);
    setOpenFormId(id);
    setShowFooterButtons(false);
    setShowDrawer(false);
  };

  const closeHandler = () => {
    onCloseForm();
    setOpenFormId(null);
    setShowDrawer(true);
    setSelectedService("");
    setShowTaggingForm(false);
  };

  return (
    <>
      {sortedReplies.map((groupedReplies) => {
        if (groupedReplies.length > 1) {
          return (
            <ReplyGroup
              replies={groupedReplies}
              toggleOpenForm={toggleOpenForm}
              openFormUrl={openFormUrl}
              openFormId={openFormId}
              setOpenFormUrl={setOpenFormUrl}
              closeHandler={closeHandler}
              selectedService={selectedService}
              handleServiceChange={handleServiceChange}
              highlightedId={highlightedId}
            />
          );
        } else {
          return (
            <ReplyItem
              reply={groupedReplies[0]}
              toggleOpenForm={toggleOpenForm}
              openFormUrl={openFormUrl}
              openFormId={openFormId}
              setOpenFormUrl={setOpenFormUrl}
              closeHandler={closeHandler}
              selectedService={selectedService}
              handleServiceChange={handleServiceChange}
              highlightedId={highlightedId}
            />
          );
        }
      })}
    </>
  );
};

export default SortedReplies;

const ReplyGroup = ({
  replies,
  toggleOpenForm,
  setOpenFormUrl,
  openFormId,
  openFormUrl,
  closeHandler,
  selectedService,
  handleServiceChange,
  highlightedId,
}) => {
  const ticket = useTicket();
  const { showTaggingForm } = useContext(AppContext);
  const organisationReply = _.find(
    replies,
    (reply) => reply.channel === "organisation",
  );
  const vendorReply = _.find(replies, (reply) => reply.channel === "vendor");

  const groupIds = concatId(replies);
  const isGroupHighlighted = highlightedId && groupIds.includes(highlightedId);

  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      sx={{
        marginBottom: "20px",
      }}
      id={`reply-${groupIds}`}
    >
      {organisationReply && (
        <Grid item xs={6} key={organisationReply.id}>
          <ReplyCard
            selected={isGroupHighlighted}
            reply={organisationReply}
            toggleOpenForm={() => toggleOpenForm(organisationReply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        </Grid>
      )}
      {vendorReply && (
        <Grid item xs={6} key={vendorReply.id}>
          <ReplyCard
            selected={isGroupHighlighted}
            reply={vendorReply}
            toggleOpenForm={() => toggleOpenForm(vendorReply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        </Grid>
      )}
      {replies.map(
        (reply) =>
          openFormId === reply.id && (
            <Box
              key={reply.id}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                margin: 2,
                padding: 2,
                borderRadius: 2,
                position: "relative",
                zIndex: 3,
              }}
            >
              {showTaggingForm ? (
                <TaggingForm
                  formUrl={openFormUrl}
                  onClose={closeHandler}
                  reply={reply}
                />
              ) : (
                <NewMessageActionForm
                  formUrl={openFormUrl}
                  onClose={closeHandler}
                  stakeholderContacts={ticket?.stakeholderContacts}
                  reply={reply}
                  setOpenFormUrl={setOpenFormUrl}
                  selectedService={selectedService}
                  handleServiceChange={handleServiceChange}
                />
              )}
            </Box>
          ),
      )}
    </Grid>
  );
};

const ReplyItem = ({
  reply,
  toggleOpenForm,
  setOpenFormUrl,
  openFormId,
  openFormUrl,
  closeHandler,
  selectedService,
  handleServiceChange,
  highlightedId,
}) => {
  const ticket = useTicket();
  const { showTaggingForm } = useContext(AppContext);
  const isOrganisation = reply.channel === "organisation";
  const isAgent =
    reply.channel === "agent" && reply.stakeholderName === "Agent";

  const replyIds = concatId([reply]);
  const isHighlighted = highlightedId && replyIds.includes(highlightedId);

  return (
    <>
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: isOrganisation ? "flex-start" : "flex-end",
        }}
        key={reply.id}
        id={`reply-${replyIds}`}
      >
        <Box
          sx={{
            width: "50%",
            marginLeft: isAgent ? "25%" : "0%",
            marginRight: isAgent ? "25%" : "0%",
            paddingRight: isOrganisation ? "8px" : "0px",
            paddingLeft: !isOrganisation && !isAgent ? "8px" : "0px",
            mb: 1,
          }}
        >
          <ReplyCard
            selected={isHighlighted}
            reply={reply}
            toggleOpenForm={() => toggleOpenForm(reply.id)}
            setOpenFormUrl={setOpenFormUrl}
          />
        </Box>
      </Box>
      {openFormId === reply.id && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            mb: 2,
            padding: 2,
            borderRadius: 2,
            position: "relative",
            zIndex: 3,
          }}
        >
          {showTaggingForm ? (
            <TaggingForm
              formUrl={openFormUrl}
              onClose={closeHandler}
              reply={reply}
            />
          ) : (
            <NewMessageActionForm
              formUrl={openFormUrl}
              onClose={closeHandler}
              stakeholderContacts={ticket?.stakeholderContacts}
              reply={reply}
              setOpenFormUrl={setOpenFormUrl}
              selectedService={selectedService}
              handleServiceChange={handleServiceChange}
            />
          )}
        </Box>
      )}
    </>
  );
};
