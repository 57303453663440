export enum Channel {
  Organisation = "organisation",
  Agent = "agent",
  Vendor = "vendor",
}

export interface BinGroup {
  id: number;
  name: string;
  legendFillColor: string;
  legendBorderColor: string;
}

export interface BinType {
  id: number;
  name: string;
  groupName: string;
  binGroupId: number;
}

export interface CollectionUnit {
  quantity: string | number;
  serviceCode: string;
  visitActionId: string;
}

export interface DeliveryUnit {
  serviceCode: string;
  quantity: string | number;
}

export interface Specification {
  serviceCode: string;
  quantity: string | number;
  visitActionId: string;
}

interface ClientFeedback {
  reportedOn: string;
  deliveryResultSlug: "total_failure" | "partial_failure" | "success";
  collectionResultSlug: "total_failure" | "partial_failure" | "success";
  collectedBinGroupIds: number[];
  uncollectedBinGroupIds: number[];
  type: "client" | "vendor";
}

interface VendorFeedback extends ClientFeedback {
  collectionFailureReasonSlug: string;
  deliveryFailureReasonSlug: string;
}

export interface FailedVisitReport {
  id: string;
  linkedTicketIds: number[];
  reportedOn: string;
  vendorId: number;
  serviceUuid: string;
  locationUuid: string;
  date: string;
  collectionResultSlug: string;
  deliveryResultSlug: string;
  reportedByStakeholderId: number;
  clientFeedback: ClientFeedback[];
  vendorFeedback: VendorFeedback[];
  reportNotes: any[] | null;
  serviceId: number;
  notes: Note[];
}

interface AdhocVisitRequestAction {
  value: string;
  label: string;
  path: string;
}

interface Note {
  userId: number;
  userName: string;
  text: string;
  timestamp: string;
}

export interface AdhocVisitRequest {
  id: string;
  type: string;
  clearAll: boolean;
  date: string | null;
  cancelledOn: string | null;
  requestedAt: string;
  linkedTicketIds: number[];
  isActive: boolean;
  isCancelled: boolean;
  isConfirmed: boolean;
  collectionUnits: Record<string, any>;
  deliveryUnits: Record<string, any>;
  actions: AdhocVisitRequestAction[];
  requestDeliveryCodes: string[];
  notes: Note[];
}

export type AdhocVisitRequests = AdhocVisitRequest[];

export interface StockDeliveryRequest {
  id: string;
  type: string;
  date: string;
  confirmedOn: string | null;
  cancelledOn: string | null;
  requestedAt: string;
  linkedTicketIds: number[];
  isCancelled: boolean;
  isConfirmed: boolean;
  isActive: boolean;
  deliveryUnits: Record<string, DeliveryUnit>;
  requestDeliveryCodes: string[];
  notes: Note[];
  actions: AdhocVisitRequestAction[];
}

export type StockDeliveryRequests = StockDeliveryRequest[];

export interface SpecChangeRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  specifications: Record<string, Specification>;
  notes: Note[];
}

export type SpecChangeRequests = SpecChangeRequest[];

export interface VisitFrequencyChangeRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  requestedVisitPlanName: string;
  intervals: number[];
  notes: Note[];
}

export type VisitFrequencyChangeRequests = VisitFrequencyChangeRequest[];

export interface ServiceSuspensionRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  reasonName: string;
  withImmediateEffect: boolean;
  effectiveFrom: string;
  notes: Note[];
}

export type ServiceSuspensionRequests = ServiceSuspensionRequest[];

export interface ServiceTerminationRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  finalCollectionRequired: boolean;
  finalCollectionRequiredBefore: string;
  notes: Note[];
}

export type ServiceTerminationRequests = ServiceTerminationRequest[];

export interface ServiceResumptionRequest {
  id: string;
  requestedAt: string;
  linkedTicketIds: number[];
  type: string;
  effectiveFrom: string;
  notes: Note[];
}

export type ServiceResumptionRequests = ServiceResumptionRequest[];
