import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Alert, Box, Collapse, ListItemButton } from "@mui/material";
import { groupBy, map, orderBy } from "lodash";
import { useMemo, useState } from "react";
import { FailedVisitReport } from "service/ticket/types";
import { getTicketId } from "ticket/selectors";
import ReportVisitFailureCard from "./ReportVisitFailureCard";

interface ReportTabProps {
  failedVisitReports: FailedVisitReport[];
}

const ReportTab: React.FC<ReportTabProps> = ({ failedVisitReports }) => {
  const ticketId = getTicketId();
  const [openStates, setOpenStates] = useState(() => {
    let initialStates = {};
    if (failedVisitReports) {
      initialStates = failedVisitReports.reduce((acc, report) => {
        const isTicketId = report.linkedTicketIds[0] === Number(ticketId);
        acc[report.id] = false;
        acc[`group-${report.linkedTicketIds[0]}`] = isTicketId;
        return acc;
      }, {});
    }
    return initialStates;
  });

  const handleClick = (reportId: string) => {
    setOpenStates((prevStates: { [x: string]: boolean }) => ({
      ...prevStates,
      [reportId]: !prevStates[reportId],
    }));
  };

  const sortedReports = useMemo(() => {
    return orderBy(failedVisitReports, "reportedOn", "desc");
  }, [failedVisitReports]);

  const groupedReports = groupBy(
    sortedReports,
    (report) => report?.linkedTicketIds[0]
  );

  const renderGroup = (reports: FailedVisitReport[], currentTicketId) => (
    <Box key={currentTicketId}>
      <ListItemButton
        onClick={() => handleClick(`group-${currentTicketId}`)}
        sx={{ fontSize: "12px", fontWeight: "bold", pb: 1, color: "#fff" }}
      >
        {currentTicketId === ticketId
          ? "Reports on this Ticket"
          : `Ticket ID: ${currentTicketId}`}
        {openStates[`group-${currentTicketId}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={openStates[`group-${currentTicketId}`]}
        timeout="auto"
        unmountOnExit
      >
        {reports.map((report) => (
          <ReportVisitFailureCard
            report={report}
            isOpen={openStates[report.id]}
            toggleOpen={() => handleClick(report.id)}
          />
        ))}
      </Collapse>
    </Box>
  );
  return (
    <>
      {sortedReports?.length === 0 && (
        <Box sx={{ p: 2 }}>
          <Alert severity="warning" sx={{ fontSize: "14px" }}>
            No reports to show
          </Alert>
        </Box>
      )}
      {groupedReports[ticketId] &&
        renderGroup(groupedReports[ticketId], ticketId)}

      {map(groupedReports, (reports, currentTicketId) => {
        if (currentTicketId !== ticketId.toString()) {
          return renderGroup(reports, currentTicketId);
        }
      })}
    </>
  );
};

export default ReportTab;
