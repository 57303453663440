import { SxProps, Theme } from "@mui/system";

export const getBorderColor = (stakeholderName: string) => {
  const colors = {
    Agent: "#DD9907",
    Vendor: "#F60C0C",
    default: "#8CC152",
  };
  return colors[stakeholderName] || colors.default;
};

const card = (reply: any): SxProps<Theme> => ({
  borderColor: getBorderColor(reply.stakeholderName),
  borderRadius: (theme: Theme) => theme.spacing(1),
  borderWidth: "1px",
  borderStyle: "solid",
  backgroundColor: "transparent",
  width: "100%",
  color: (theme: Theme) => theme.palette.white.main,
  height: "100%",
  position: "relative",
});

const cardContent: SxProps<Theme> = {
  whiteSpace: "pre-line",
  fontSize: "12px",
  color: "white",
};

const processContainer: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "12px",
  marginTop: (theme: Theme) => theme.spacing(2),
};

const processed: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

const cardActions: SxProps<Theme> = {
  padding: "8px 16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  mt: 'auto'
};

const actionButton: SxProps<Theme> = {
  color: "#4FC1E9",
  borderColor: "#4FC1E9",
};

const responseButtons = (theme: Theme): SxProps<Theme> => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
});

const tooltipStyles: SxProps<Theme> = {
  tooltip: {
    color: "#262B30",
    backgroundColor: "#FFF",
    fontSize: "14px",
    fontWeight: 500,
  },
  arrow: {
    color: "#fff",
  },
};

const notProcessedContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const notProcessedBox: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",
};

const buttonsBox: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

const successButton: SxProps<Theme> = {
  borderRadius: (theme) => theme.spacing(1),
  fontSize: "11px",
};

const errorButton: SxProps<Theme> = {
  borderRadius: (theme) => theme.spacing(1),
  fontSize: "11px",
};

const flexCenterGap: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "2px",
};

const successText: SxProps<Theme> = {
  color: (theme) => theme.palette.success.main,
};

const successMain: SxProps<Theme> = {
  color: "success.main",
};

const errorText: SxProps<Theme> = {
  color: (theme) => theme.palette.error.main,
};

const errorMain: SxProps<Theme> = {
  color: "error.main",
};

export const styles = {
  card,
  cardContent,
  processContainer,
  processed,
  cardActions,
  actionButton,
  responseButtons,
  tooltipStyles,
  notProcessedContainer,
  notProcessedBox,
  buttonsBox,
  successButton,
  errorButton,
  flexCenterGap,
  successText,
  successMain,
  errorText,
  errorMain,
};
