import { alpha } from "@mui/material";
import { useGetLocationServiceVendorQuery } from "api/services";
import _, { forEach } from "lodash";
import { getLocationId, getVendorId } from "ticket/selectors";
import { NestedDropdown } from "mui-nested-menu";
import TagIcon from "@mui/icons-material/Tag";

const TaggingFormButton = ({ onClickHandler, channel = undefined }) => {
  const locationId = getLocationId();
  const vendorId = getVendorId();
  const { data: locationServiceVendors } = useGetLocationServiceVendorQuery({
    locationId,
    vendorId,
  });

  let actions = new Map();

  forEach(locationServiceVendors, (location) => {
    if (location?.services) {
      forEach(location.services, (service) => {
        forEach(service?.actions, (action) =>
          actions.set(action.value, action)
        );
      });
    }
  });

  const actionsArray = Array.from(actions.values());

  const filteredActions = actionsArray?.filter((action) => {
    if (action?.restrictToStakeholder && channel) {
      return action.restrictToStakeholder === channel;
    } else {
      return true;
    }
  });

  const transformMenuItem = (item) => {
    if (item.items && item.items.length > 0) {
      return {
        ...item,
        items: item.items.map(transformMenuItem),
      };
    } else {
      return {
        ...item,
        callback: (event) => onClickHandler(event, item),
      };
    }
  };

  const transformGroup = (items, groupLabel) => {
    const itemsWithCallback = items.map(transformMenuItem);
    if (items.length === 1 && items[0]?.label === groupLabel) {
      return itemsWithCallback[0];
    } else {
      return {
        label: groupLabel,
        items: itemsWithCallback,
      };
    }
  };

  const grouped = _.groupBy(
    filteredActions,
    (item) => item?.group || item?.label
  );
  const transformedMenuItems = _.map(grouped, transformGroup);

  const menuItemsData = {
    label: "",
    items: transformedMenuItems,
  };

  return (
    <NestedDropdown
      menuItemsData={menuItemsData}
      MenuProps={{
        elevation: 3,
      }}
      ButtonProps={{
        variant: "outlined",
        size: "small",
        startIcon: <TagIcon />,
        sx: {
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "12px",
          px: 0,
          minWidth: "34px",
          "& .MuiButton-startIcon": {
            m: 0,
            p: 0,
          },
          "& .MuiButton-endIcon": {
            m: 0,
            p: 0,
            display: "inherit",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "18px",
          },
          "&:hover": {
            borderColor: "#fff",
            backgroundColor: alpha("#fff", 0.1),
            color: "#fff",
          },
        },
      }}
    />
  );
};

export default TaggingFormButton;
