import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import BinDot from "service/BinDot";
import { useBinFetch } from "sharedUtils";
import {
  StyledTableBody,
  StyledTableContainer,
  StyledTableRow,
} from "./forms/common/CustomTable";
import { DeliveryUnit } from "./ticket/types";

interface DeliveryInstructionProps {
  deliveryUnits: Record<string, DeliveryUnit>;
  requestDeliveryCodes: string[];
  status: "confirmation" | "cancelation";
}

const DeliveryInstruction: React.FC<DeliveryInstructionProps> = ({
  deliveryUnits,
  requestDeliveryCodes,
  status,
}) => {
  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const rows = _.map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  const hasAtLeastOneObject = useMemo(
    () => _.some(deliveryUnits, _.isPlainObject),
    [deliveryUnits],
  );

  const renderNoDelivery = () => (
    <li>
      <strong>No Additional Stock Delivery Requested</strong>
    </li>
  );

  return hasAtLeastOneObject ? (
    <DeliveryTable rows={rows} status={status} />
  ) : (
    renderNoDelivery()
  );
};

interface DeliveryTableProps {
  rows: JSX.Element[];
  label?: boolean;
  status?: "confirmation" | "cancelation";
}

export const DeliveryTable: React.FC<DeliveryTableProps> = ({
  rows,
  label = true,
  status,
}) => (
  <>
    {label && (
      <li>
        <strong>
          {status === "confirmation"
            ? "Vendor has confirmed to deliver the following in addition to like for like"
            : "Vendor was requested to deliver the following in addition to like for like"}
        </strong>
      </li>
    )}
    <StyledTableContainer>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Quantity</TableCell>
          </StyledTableRow>
        </TableHead>
        <StyledTableBody>{rows}</StyledTableBody>
      </Table>
    </StyledTableContainer>
  </>
);

export default DeliveryInstruction;
