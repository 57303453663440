import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  List,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import _, { forEach, map, size, startCase, toLower } from "lodash";
import React, { useContext } from "react";
import BinDot from "service/BinDot";
import { AdhocVisitRequest } from "service/ticket/types";
import { formatDate, useBinFetch } from "sharedUtils";
import AppContext from "ticket/AppContext";
import AdhocVisitRequestCardDetails, {
  getRequestType,
  getTypographyLabel,
} from "./AdhocVisitRequestCardDetails";
import ShowMoreDivider from "service/forms/common/ShowMoreDivider";
import Debugger from "service/forms/Debugger";
import LinkIcon from "@mui/icons-material/Link";
import { getTicketId } from "ticket/selectors";
import TagIcon from "@mui/icons-material/Tag";
import CustomTimelineNotes from "service/forms/common/CustomTimeLineNotes";

interface AdhocVisitRequestCardProps {
  request: AdhocVisitRequest;
  isOpen: boolean;
  toggleOpen: (open: any) => void;
}

const AdhocVisitRequestCard: React.FC<AdhocVisitRequestCardProps> = ({
  request,
  isOpen,
  toggleOpen,
}) => {
  const {
    isCancelled,
    isActive,
    isConfirmed,
    actions,
    deliveryUnits,
    collectionUnits,
    requestDeliveryCodes,
    clearAll,
    notes,
  } = request;

  const { selectedContainer } = useContext(AppContext);

  const collectAndDeliver = { ...collectionUnits };
  forEach(deliveryUnits, (deliveryUnit) => {
    collectAndDeliver[deliveryUnit.serviceCode] = {
      ...collectAndDeliver[deliveryUnit.serviceCode],
      deliveryQty: deliveryUnit.quantity,
    };
  });

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;

  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });
  const label = getTypographyLabel(requestType);

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  const collectionRows = _.map(
    collectionUnits,
    ({ serviceCode, quantity, visitActionId }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{_.startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    },
  );

  const collectAndDeliveryRows = _.map(
    collectAndDeliver,
    ({ serviceCode, quantity, visitActionId, deliveryQty }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{deliveryQty}</TableCell>
          <TableCell>{_.startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    },
  );

  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        position: "relative",
        ...(selectedContainer === request.id && {
          "& .MuiPaper-root": {
            border: "3px solid #6c8c89",
          },
        }),
      }}
      key={request.id}
      data-link-card={request.id}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          background: "#262B30",
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
          "& *": {
            fontSize: "12px",
            color: "#fff",
          },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Adhoc Visit Request
              </Typography>
              <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
                on {formatDate(request?.requestedAt)}
              </Typography>
            </Box>

            <ActionButtons
              actions={actions}
              isActive={isActive}
              request={request}
              linkSelected={selectedContainer === request.id}
            />
          </Stack>

          {isConfirmed && (
            <Typography
              fontSize={"12px"}
              fontWeight={"bold"}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "#2dcfba",
              }}
            >
              <LocalShippingIcon sx={{ fill: "#2dcfba" }} />
              Confirmed by vendor on {formatDate(request?.date)}
            </Typography>
          )}

          {isCancelled && (
            <Typography
              fontSize={"12px"}
              fontWeight={"bold"}
              sx={{ display: "flex", color: "#F60C0C" }}
            >
              <CloseIcon sx={{ fill: "#F60C0C" }} />
              Cancelled on {formatDate(request?.cancelledOn)}
            </Typography>
          )}
          <Debugger {...request} />

          <Typography variant="h6">{label}</Typography>
          {(isCollect || isDelivery) && (
            <List sx={{ width: "100%" }} component="nav">
              <ShowMoreDivider toggleOpen={toggleOpen} isOpen={isOpen} />

              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <AdhocVisitRequestCardDetails
                  clearAll={clearAll}
                  isCollectAndDelivery={isCollectAndDelivery}
                  isCollect={isCollect}
                  isDelivery={isDelivery}
                  deliveryRows={deliveryRows}
                  collectionRows={collectionRows}
                  collectAndDeliveryRows={collectAndDeliveryRows}
                />
                <CustomTimelineNotes notes={notes} />
              </Collapse>
            </List>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdhocVisitRequestCard;

export const ActionButtons = ({ actions, isActive, request, linkSelected }) => {
  const ticketId = getTicketId();
  const { onSelectAction, setSelectedContainer } = useContext(AppContext);
  const scrollToTagInCompositeId = () => {
    const specificId = request.id;
    setSelectedContainer(specificId);

    const element = document.getElementById(specificId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const potentialTargets = document.querySelectorAll("[id]");
      const targetsArray = Array.from(potentialTargets); // Convert NodeList to Array
      for (let el of targetsArray) {
        if (el.id.includes(specificId)) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
          break;
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isActive && (
        <>
          {actions?.map((action) => (
            <Tooltip
              arrow
              placement="top"
              title={startCase(action.value)}
              key={action.value}
            >
              <IconButton
                aria-label={action.label}
                onClick={() => onSelectAction(null, { path: action.path })}
                sx={{ py: 0 }}
              >
                {toLower(action.label) === "confirm" ? (
                  <CheckOutlinedIcon sx={{ color: "#AAB2BD" }} />
                ) : (
                  <HighlightOffOutlinedIcon sx={{ color: "#AAB2BD" }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        </>
      )}
      {ticketId === request?.linkedTicketIds[0] ? (
        <Tooltip arrow placement="top" title="Go to reply">
          <IconButton
            onClick={scrollToTagInCompositeId}
            sx={{
              ...(linkSelected && {
                border: "1px solid #6c8c89",
              }),
            }}
          >
            <LinkIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="top" title="Go to ticket">
          <IconButton
            onClick={() =>
              window.open(
                `/admin/tickets/${request?.linkedTicketIds[0]}/app`,
                "_blank",
              )
            }
          >
            <TagIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
