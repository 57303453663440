import { Card, CardActions } from "@mui/material";
import { TicketReply, useEditTicketReplyMutation } from "api/tickets";
import { useContext } from "react";
import Debugger from "service/forms/Debugger";
import LogComponent from "service/request-logs/LogComponent";
import { updateQueryStringParameter } from "sharedUtils";
import AppContext from "ticket/AppContext";
import ActionMenuButton from "../actions-menu/ActionMenuButton";
import TaggingFormButton from "../actions-menu/TaggingFormButton";
import ReplyCardContent from "./ReplyCardContent";
import ReplyCardHeader from "./ReplyCardHeader";
import { styles } from "./ReplyCardStyles";

const ReplyCard = ({
  reply,
  toggleOpenForm,
  setOpenFormUrl,
  selected,
}: {
  reply: TicketReply;
  toggleOpenForm;
  setOpenFormUrl;
  selected;
}) => {
  const { setShowTaggingForm } = useContext(AppContext);
  const { channel, url, tags } = reply;
  const { activeServices } = useContext(AppContext);

  const [updateTicketReply, { isLoading }] = useEditTicketReplyMutation();

  const handleUpdateTicketReply = async (
    newProcess: boolean,
    newResponse: string,
  ) => {
    try {
      await updateTicketReply({
        url,
        processed: newProcess,
        channel,
        response: newResponse,
      }).unwrap();
    } catch (error) {
      console.error("Failed", error);
    }
  };

  const onClickHandler = (_e: any, option: any) => {
    toggleOpenForm();

    if (activeServices.length === 1) {
      const serviceId = activeServices[0].id;
      setOpenFormUrl((_prevUrl) =>
        updateQueryStringParameter(
          option.path,
          "source_ticket_reply_id",
          reply.id,
        ).replace(/(\/service_actions\/)\d+/, `$1${serviceId}`),
      );
    } else {
      setOpenFormUrl(
        updateQueryStringParameter(
          option.path,
          "source_ticket_reply_id",
          reply.id,
        ),
      );
    }
  };

  const taggingFormOnClickHandler = (_e: any, option: any) => {
    toggleOpenForm();
    setShowTaggingForm(true);
    setOpenFormUrl(
      updateQueryStringParameter(
        option.path,
        "source_ticket_reply_id",
        reply.id,
      ),
    );
  };

  return (
    <Card
      sx={{
        ...styles.card(reply),
        ...(selected && {
          boxShadow: "0 0 10px #DD9907",
          background: "#2A2518",
        }),
        display: "flex",
        flexDirection: "column",
      }}
      key={reply.id}
    >
      <ReplyCardHeader reply={reply} />
      <ReplyCardContent
        reply={reply}
        handleUpdateTicketReply={handleUpdateTicketReply}
        isLoading={isLoading}
      />
      {channel !== "agent" && (
        <CardActions
          sx={{
            padding: "8px 16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            mt: "auto",
          }}
        >
          <ActionMenuButton onClickHandler={onClickHandler} channel={channel} />
          <TaggingFormButton
            onClickHandler={taggingFormOnClickHandler}
            channel={channel}
          />
        </CardActions>
      )}
      <Debugger {...reply} />
      {reply?.event_data.length > 0 && (
        <LogComponent eventData={reply.event_data} />
      )}
    </Card>
  );
};

export default ReplyCard;
