import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export const StyledTableContainer = styled(TableContainer)(() => ({
  marginY: 1,
  "& .MuiTableCell-root": {
    fontSize: "11px",
    fontWeight: "inherit",
    color: "inherit",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  textWrap: "nowrap",
  fontWeight: "bold",
  color: "#828B98",
  border: "1px solid transparent",
}));

export const StyledTableBody = styled(TableBody)(() => ({
  "& .MuiTableCell-root": {
    borderTop: "1px solid #F1F1F5",
    borderBottom: "1px solid #F1F1F5",
  },
}));

const CustomTable = ({
  rows,
  headers = ["Type", "Name", "Code", "Cancel Deliver # Units"],
}) => {
  return (
    <StyledTableContainer>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <StyledTableBody>{rows}</StyledTableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default CustomTable;
