import { orderBy } from "lodash";
import { useGetServiceQuery } from "../../modules/api/services";
import { useTypedSelector } from "./app/store";
import {
  useGetAdhocVisitRequestsByLocationAndVendorQuery,
  useGetAdhocVisitRequestsQuery,
  useGetFailedVisitReportsQuery,
  useGetRemediationCasesQuery,
  useGetServiceSuspensionRequestsQuery,
  useGetServiceTerminationRequestsQuery,
  useGetServiceResumptionRequestsQuery,
  useGetSpecChangeRequestsQuery,
  useGetVisitFrequencyChangeRequestsQuery,
  useGetStockDeliveryRequestsQuery,
  useGetTicketQuery,
  useGetTicketRepliesQuery,
} from "api/tickets";
import {
  AdhocVisitRequests,
  FailedVisitReport,
  StockDeliveryRequest,
  StockDeliveryRequests,
  SpecChangeRequest,
  SpecChangeRequests,
  VisitFrequencyChangeRequest,
  VisitFrequencyChangeRequests,
  ServiceSuspensionRequest,
  ServiceSuspensionRequests,
  ServiceTerminationRequest,
  ServiceTerminationRequests,
  ServiceResumptionRequest,
  ServiceResumptionRequests,
} from "service/ticket/types";

export const getTicketId = () => useTypedSelector((state) => state.ticketId);
export const getLocationId = () =>
  useTypedSelector((state) => state.locationId);
export const getVendorId = () => useTypedSelector((state) => state.vendorId);

export const useTicket = () => {
  const { data: ticket } = useGetTicketQuery(getTicketId());
  return ticket;
};

export const useTicketReplies = () => {
  const { data: replies = [] } = useGetTicketRepliesQuery(getTicketId());
  return replies;
};

export const getDefaultContactId = (channel) =>
  useTypedSelector((state) => {
    const { defaultContactMap } = useTicket();
    return defaultContactMap[channel].id;
  });
export const getDefaultResponseExpected = (channel) => {
  switch (channel) {
    case "organisation":
      return false;
    case "vendor":
      return true;
    default:
      return true;
  }
};

//TODO DONT USE SERVICE SELECTORS in lower components and remove from app props

export const getServiceIds = () =>
  useTypedSelector((state) => state.serviceIds);

export const useService = (id: string) => {
  const { data: service } = useGetServiceQuery(id);
  return service;
};

export const useServices = (ids) => {
  return ids?.map((i) => useService(i));
};

export const getAllServices = () => {
  return useServices(getServiceIds());
};

//TODO END

export const useFailedVisitReports = (): FailedVisitReport[] | undefined => {
  const { data } = useGetFailedVisitReportsQuery(getTicketId());
  return data;
};

// Adhoc and Stock are returned as arrays
export const useAdhocVisitRequests = (): AdhocVisitRequests => {
  const { data } = useGetAdhocVisitRequestsQuery(getTicketId());
  const { adhocVisitRequests } = data || {};
  if (adhocVisitRequests) {
    return orderBy(adhocVisitRequests, ["requestedAt"], ["desc"]);
  }
};

export const useAdhocVisitRequestsByLocationAndVendor = () => {
  const queryObject = {
    locationId: getLocationId(),
    vendorId: getVendorId(),
  };

  const { data } =
    useGetAdhocVisitRequestsByLocationAndVendorQuery(queryObject);
  return data;
};

export const useStockDeliveryRequests = (): StockDeliveryRequests => {
  const { data } = useGetStockDeliveryRequestsQuery(getTicketId());
  const stockDeliveryRequests: StockDeliveryRequests =
    data?.stockDeliveryRequests;
  if (stockDeliveryRequests) {
    return orderBy(stockDeliveryRequests, ["requestedAt"], ["desc"]);
  }
};

export const useRemediationCases = () => {
  const { data } = useGetRemediationCasesQuery(getTicketId());
  const { remediationCases: remediationCase } = data || {};

  // Return first case, only can exist 1 ?
  const remediationCaseValue =
    remediationCase && Object.keys(remediationCase).length > 0
      ? Object.values(remediationCase)[0]
      : null;

  return remediationCaseValue;
};

export const useServiceSuspensionRequests = (): ServiceSuspensionRequests => {
  const { data } = useGetServiceSuspensionRequestsQuery(getTicketId());
  const { serviceSuspensionRequests } = data || {};
  if (serviceSuspensionRequests) {
    return orderBy(serviceSuspensionRequests, ["requestedAt"], ["desc"]);
  }
};

export const useServiceTerminationRequests = (): ServiceTerminationRequests => {
  const { data } = useGetServiceTerminationRequestsQuery(getTicketId());
  const { serviceTerminationRequests } = data || {};
  if (serviceTerminationRequests) {
    return orderBy(serviceTerminationRequests, ["requestedAt"], ["desc"]);
  }
};

export const useServiceResumptionRequests = (): ServiceResumptionRequests => {
  const { data } = useGetServiceResumptionRequestsQuery(getTicketId());
  const { serviceResumptionRequests } = data || {};
  if (serviceResumptionRequests) {
    return orderBy(serviceResumptionRequests, ["requestedAt"], ["desc"]);
  }
};

export const useSpecChangeRequests = (): SpecChangeRequests => {
  const { data } = useGetSpecChangeRequestsQuery(getTicketId());
  const { specChangeRequests } = data || {};
  if (specChangeRequests) {
    return orderBy(specChangeRequests, ["requestedAt"], ["desc"]);
  }
};

export const useVisitFrequencyChangeRequests = (): VisitFrequencyChangeRequests => {
  const { data } = useGetVisitFrequencyChangeRequestsQuery(getTicketId());
  const { visitFrequencyChangeRequests } = data || {};
  if (visitFrequencyChangeRequests) {
    return orderBy(visitFrequencyChangeRequests, ["requestedAt"], ["desc"]);
  }
};

export const useDeveloper = () => {
  const developer = useTypedSelector((state) => state.developer);
  return developer;
};
