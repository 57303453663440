import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTypedSelector } from "../../../../apps/ticket/app/store";
import { useGetTicketQuery } from "../../../api/tickets";
import { Channel } from "../types";
import ChannelHeaderButton from "./ChannelHeaderButton";
import { channelConfigs, styles } from "./ChannelHeaderStyles";

export interface TitleProps {
  type: Channel;
  responseExpected: boolean;
}

const Title: React.FC<TitleProps> = ({ type, responseExpected }) => {
  const { label } = channelConfigs[type];

  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.spacing(1),
        background: channelConfigs[type].color,
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        py: 0,
      }}
    >
      <Typography sx={styles.channelTypographyStyles}>
        <span style={{ fontWeight: "bold" }}>{label}</span> channel
      </Typography>
      {/* <ChannelHeaderButton responseExpected={responseExpected} type={type} /> */}
    </Box>
  );
};

const ChannelHeader = ({ showOverview, onClickShowOverview, primaryTab }) => {
  const ticketId = useTypedSelector((state) => state.ticketId);
  const { data: ticket } = useGetTicketQuery(ticketId);

  return (
    <Box
      sx={{ ...styles.channelHeaderContainer, top: showOverview ? 40 : 120 }}
    >
      {primaryTab === 0 && (
        <Tooltip
          title="Toggle scroll on replies section"
          arrow
          placement="top"
          sx={{
            position: "absolute",
            top: "-40px",
            left: "-32px",
          }}
        >
          <IconButton onClick={onClickShowOverview}>
            <MenuIcon sx={{ fill: "#fff" }} />
          </IconButton>
        </Tooltip>
      )}
      <Title
        type={Channel.Organisation}
        responseExpected={ticket?.organisationResponseExpected}
      />
      <Title
        type={Channel.Vendor}
        responseExpected={ticket?.vendorResponseExpected}
      />
    </Box>
  );
};

export default ChannelHeader;
