import { Tooltip } from "@mui/material";
import PartialCollectionTooltip from "./PartialCollectionTooltip";
import { last, startCase } from "lodash";
import { colorForSlug } from "./ReportVisitFailureCard";
import { FailedVisitReport } from "service/ticket/types";

interface ReportVisitFailureCardTableProps {
  report: FailedVisitReport;
}

const ReportVisitFailureCardTable: React.FC<
  ReportVisitFailureCardTableProps
> = ({ report }) => {
  let reasonForFailure;
  let deliveryFailureReasonSlugText;
  let collectionFailureReasonSlugText;

  const vendorFeedback = report?.vendorFeedback && last(report?.vendorFeedback);
  if (vendorFeedback) {
    reasonForFailure =
      vendorFeedback?.deliveryFailureReasonSlug ||
      vendorFeedback?.collectionFailureReasonSlug;

    deliveryFailureReasonSlugText = vendorFeedback?.deliveryFailureReasonSlug;
    collectionFailureReasonSlugText =
      vendorFeedback?.collectionFailureReasonSlug;
  } else {
    reasonForFailure =
      report?.deliveryFailureReasonSlug || report?.collectionFailureReasonSlug;

    deliveryFailureReasonSlugText = report?.deliveryFailureReasonSlug;
    collectionFailureReasonSlugText = report?.collectionFailureReasonSlug;
  }

  return (
    <table
      style={{
        width: "100%",
        fontWeight: "bold",
        color: "#979797",
        fontSize: "12px",
        marginTop: "5px",
        tableLayout: "fixed",
      }}
    >
      <tr>
        <th></th>
        <th>Collection</th>
        <th>Delivery</th>
      </tr>
      <tr>
        <td>Feedback</td>
        <td style={{ color: colorForSlug[report.collectionResultSlug] }}>
          <Tooltip
            title={
              report?.collectionResultSlug === "partial_failure" && (
                <PartialCollectionTooltip feedback={report} />
              )
            }
            arrow
          >
            <div>
              {generateResultTooltipText(
                report.collectionResultSlug,
                "collected",
              )}
            </div>
          </Tooltip>
        </td>
        <td style={{ color: colorForSlug[report.deliveryResultSlug] }}>
          {generateResultTooltipText(report.deliveryResultSlug, "delivered")}
        </td>
      </tr>
      {reasonForFailure && (
        <tr>
          <td>Reason for Failure</td>
          <td style={{ color: "#fff" }}>
            {startCase(collectionFailureReasonSlugText)}
          </td>
          <td style={{ color: "#fff" }}>
            {startCase(deliveryFailureReasonSlugText)}
          </td>
        </tr>
      )}
    </table>
  );
};

export default ReportVisitFailureCardTable;

export const generateResultTooltipText = (resultSlug, type) => {
  switch (resultSlug) {
    case "total_failure":
      return `Nothing was ${type}`;
    case "partial_failure":
      return `Some was ${type}`;
    case "success":
      return `All stock was ${type}`;
  }
};
