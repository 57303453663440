import { Button, styled, alpha } from "@mui/material";
import { useGetLocationServiceVendorQuery } from "api/services";
import _, { forEach } from "lodash";
import { getLocationId, getVendorId } from "ticket/selectors";
import { NestedDropdown } from "mui-nested-menu";
import ReplyIcon from "@mui/icons-material/Reply";

const ACTION_COLOR = "#4FC1E9";

export const CustomOutlinedButton = styled(Button)(({}) => ({
  border: "1px solid",
  borderColor: ACTION_COLOR,
  color: ACTION_COLOR,
  fontSize: "12px",
  "&:hover": {
    borderColor: ACTION_COLOR,
    backgroundColor: alpha(ACTION_COLOR, 0.1),
    color: ACTION_COLOR,
  },
}));

const ActionMenuButton = ({ onClickHandler, channel = undefined }) => {
  const locationId = getLocationId();
  const vendorId = getVendorId();
  const { data: locationServiceVendors } = useGetLocationServiceVendorQuery({
    locationId,
    vendorId,
  });

  let actions = new Map();
  forEach(locationServiceVendors, (location) => {
    if (location?.services) {
      forEach(location.services, (service) => {
        forEach(service?.actions, (action) => {
          if (actions.has(action.value)) {
            const existingAction = actions.get(action.value);
            if (existingAction.items && existingAction.items.length === 0) {
              actions.set(action.value, action);
            } else {
              return;
            }
          } else {
            actions.set(action.value, action);
          }
        });
      });
    }
  });

  const actionsArray = Array.from(actions.values());

  const filteredActions = actionsArray?.filter((action) => {
    if (action?.restrictToStakeholder && channel) {
      return action.restrictToStakeholder === channel;
    } else {
      return true;
    }
  });

  const transformMenuItem = (item) => {
    if (item.items && item.items.length > 0) {
      return {
        ...item,
        items: item.items.map(transformMenuItem),
      };
    } else {
      return {
        ...item,
        callback: (event) => onClickHandler(event, item),
      };
    }
  };

  const transformGroup = (items, groupLabel) => {
    const itemsWithCallback = items.map(transformMenuItem);
    if (items.length === 1 && items[0]?.label === groupLabel) {
      return itemsWithCallback[0];
    } else {
      return {
        label: groupLabel,
        items: itemsWithCallback,
      };
    }
  };

  const grouped = _.groupBy(
    filteredActions,
    (item) => item?.group || item?.label,
  );
  const transformedMenuItems = _.map(grouped, transformGroup);

  const menuItemsData = {
    label: "",
    items: transformedMenuItems,
  };

  return (
    <NestedDropdown
      menuItemsData={menuItemsData}
      MenuProps={{
        elevation: 3,
      }}
      ButtonProps={{
        variant: "outlined",
        size: "small",
        startIcon: <ReplyIcon />,
        sx: {
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "12px",
          px: 0,
          minWidth: "34px",
          "& .MuiButton-startIcon": {
            m: 0,
            p: 0,
          },
          "& .MuiButton-endIcon": {
            m: 0,
            p: 0,
            display: "inherit",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "18px",
          },
          "&:hover": {
            borderColor: "#fff",
            backgroundColor: alpha("#fff", 0.1),
            color: "#fff",
          },
        },
      }}
    />
  );
};

export default ActionMenuButton;
