import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { flatMap, forEach, map, size, startCase } from "lodash";
import { useState } from "react";
import BinDot from "service/BinDot";
import AdhocVisitRequestCardDetails, {
  getRequestType,
  getTypographyLabel,
} from "service/report-request-box/AdhocVisitRequestCardDetails";
import { formatDate, useBinFetch } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const AdhocVisitRequestLog = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch();
  const { stakeholderContacts } = useTicket() || {};
  const { deliveryUnits, cancelledOn, date, notes } = request.data || {};

  const [open, setOpen] = useState(false);

  let clearAll;
  let collectionUnits;
  if (request?.data?.collectionUnits === "all") {
    clearAll = true;
    collectionUnits = [];
  } else {
    clearAll = false;
    collectionUnits = request?.data?.collectionUnits;
  }

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;
  const isCancelled = cancelledOn;
  const isConfirmed = date && request.name === "AdhocVisitRequestConfirmed";

  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });

  const label = getTypographyLabel(requestType);

  // Get all stakeholders
  const allStakeholders = flatMap(stakeholderContacts, (x) => x);

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow
        key={serviceCode}
        sx={{
          "& .MuiTableCell-root": {
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  const collectionRows = map(
    collectionUnits,
    ({ serviceCode, quantity, visitActionId }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow
          key={serviceCode}
          sx={{
            "& .MuiTableCell-root": {
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    },
  );

  const collectAndDeliver = { ...collectionUnits };
  forEach(deliveryUnits, (deliveryUnit) => {
    collectAndDeliver[deliveryUnit.serviceCode] = {
      ...collectAndDeliver[deliveryUnit.serviceCode],
      deliveryQty: deliveryUnit.quantity,
    };
  });

  const collectAndDeliveryRows = map(
    collectAndDeliver,
    ({ serviceCode, quantity, visitActionId, deliveryQty }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow
          key={serviceCode}
          sx={{
            "& .MuiTableCell-root": {
              borderTop: "1px solid rgba(255, 255, 255, 0.2)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            },
          }}
        >
          <TableCell scope="row">
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              showImg
              {...binType}
            />
          </TableCell>
          <TableCell>{binType?.name}</TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{deliveryQty}</TableCell>
          <TableCell>{startCase(visitActionId)}</TableCell>
        </TableRow>
      );
    },
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(request.name)}
        </Typography>
        <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
          on {formatDate(request?.requestedOn)} by{" "}
          {inputtedBy?.nameWithoutEmail} on {formatDate(request?.createdAt)}
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {isCancelled ? (
          <Typography variant="h6" sx={{ mb: 1 }}>
            Adhoc Visit Request Cancelled on {formatDate(cancelledOn)}
          </Typography>
        ) : isConfirmed ? (
          <Typography variant="h6" sx={{ mb: 1 }}>
            Confirmed by vendor on {formatDate(date)}
          </Typography>
        ) : (
          <Typography variant="h6">Adhoc Visit Requested</Typography>
        )}
        <Typography variant="h6">{label}</Typography>
        {(isCollect || isDelivery) && (
          <>
            <AdhocVisitRequestCardDetails
              clearAll={clearAll}
              isCollectAndDelivery={isCollectAndDelivery}
              isCollect={isCollect}
              isDelivery={isDelivery}
              deliveryRows={deliveryRows}
              collectionRows={collectionRows}
              collectAndDeliveryRows={collectAndDeliveryRows}
            />
          </>
        )}
        {notes && (
          <Typography variant="h6" sx={{ my: 1 }}>
            {notes}
          </Typography>
        )}
      </Collapse>
    </>
  );
};

export default AdhocVisitRequestLog;
