import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  alpha,
} from "@mui/material";
import { useRef, useState } from "react";
import { getServiceIds, useService, useTicket } from "ticket/selectors";
import ActionMenuButton from "../actions-menu/ActionMenuButton";
import {
  AGENT_COLOR,
  ORGANISATION_COLOR,
  VENDOR_COLOR,
} from "../channel-header/ChannelHeaderStyles";
import { Channel } from "../types";
import { ToggleButton } from "./ChannelFooterButtons";
import { FooterDrawer } from "./FooterDrawer";
import { IncomingEmailIcon } from "service/utils/Icons";
import { useCopyToClipboard } from "usehooks-ts";

const ChannelFooter = ({
  currentForm,
  isDrawerOpen,
  activeChannel,
  closeDrawer,
  handleToggleButton,
  openFormUrl,
  onSelectAction,
  showFooterButtons,
  showDrawer,
}) => {
  const ticket = useTicket();
  const serviceIds = getServiceIds();
  const services = serviceIds?.map((id) => useService(id));
  const [, copyToClipboard] = useCopyToClipboard();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const stickyBoxRef = useRef(null);

  const toggleButtonsConfig = [
    {
      color: ORGANISATION_COLOR,
      label: "Organisation",
      channel: Channel.Organisation,
    },
    {
      color: AGENT_COLOR,
      label: "Agent",
      channel: Channel.Agent,
    },
    {
      color: VENDOR_COLOR,
      label: "Vendor",
      channel: Channel.Vendor,
    },
  ];

  const onClickHandler = (config) => {
    handleToggleButton(config?.channel);
  };
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyEmail = (email) => {
    copyToClipboard(email);
    handleClose();
  };

  return (
    <Box
      sx={{
        maxHeight: "60vh",
        position: "sticky",
        bottom: "0px",
        gap: (theme) => theme.spacing(1),
        width: "100%",
        zIndex: 2,
        boxSizing: "border-box",
        borderRadius: (theme) => theme.spacing(1),
      }}
    >
      {showFooterButtons && (
        <Box
          sx={{
            padding: (theme) => theme.spacing(1),
            borderRadius: (theme) => theme.spacing(1),
            display: "flex",
            gap: (theme) => theme.spacing(1),
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            background: "#262B30",
            zIndex: 2,
            fontSize: "12px",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          ref={stickyBoxRef}
        >
          {!isDrawerOpen && (
            <ActionMenuButton
              service={services[0]}
              onClickHandler={onSelectAction}
            />
          )}
          {toggleButtonsConfig.map((config, index) => (
            <ToggleButton
              key={index}
              isActive={activeChannel === config.channel}
              color={config.color}
              label={config.label}
              onClick={() => onClickHandler(config)}
            />
          ))}
          <Tooltip title="Incoming Email" arrow>
            <Button
              onClick={handleClick}
              variant="outlined"
              size="small"
              sx={{
                border: "1px solid #fff",
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: "12px",
                minWidth: "34px",
                "&:hover": {
                  borderColor: "#fff",
                  backgroundColor: alpha("#fff", 0.1),
                  color: "#fff",
                },
              }}
            >
              <IncomingEmailIcon sx={{ color: "#fff" }} />
            </Button>
          </Tooltip>
          {ticket?.replyEmails && (
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {Object.entries(ticket?.replyEmails).map(([channel, email]) => (
                <MenuItem key={channel} onClick={() => handleCopyEmail(email)}>
                  {`Copy ${channel} email`}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
      )}

      {(isDrawerOpen || openFormUrl) && showDrawer && (
        <FooterDrawer
          openFormUrl={openFormUrl}
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
        >
          {currentForm}
        </FooterDrawer>
      )}
    </Box>
  );
};

export default ChannelFooter;
