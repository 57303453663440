import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import VisitTile from "./VisitTile";
import { useBinGroupFetch } from "sharedUtils";
import { STATUS_COLORS } from "service/forms/common/VisitBoxButtons";
import { useTypedSelector } from "ticket/app/store";

var Week = ({
  start,
  index,
  onDayClick,
  selected,
  visits,
  todayRef,
  selectedDay,
  setSelectedDay,
}) => {
  const calendarApi = useTypedSelector((state) => state.calendarApi);

  const [dateToDisplay, setDateToDisplay] = useState(null);
  const selectedRef = useRef();

  useEffect(() => {
    // Directly find if the week contains the start of a month
    for (let day = 0; day < 7; day++) {
      const checkDate = moment(start).add(day, "days");
      if (checkDate.date() === 1) {
        setDateToDisplay(checkDate);
        break; // Found the start of a month, no need to continue
      }
    }
  }, [start]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    <div
      className="calendar-week"
      ref={selected ? selectedRef : null}
      id={`date-${start.format("YYYY-MM-DD")}`}
    >
      <div className="calendar-week-label">
        {dateToDisplay && dateToDisplay.format("MMMM YYYY")}
      </div>
      {[...Array(7).keys()].map(function (_x, index) {
        const day = moment(start).add(index, "days");
        const isSelectedDay = day.format("YYYY-MM-DD") === selectedDay;
        return (
          <div
            key={index}
            className="calendar-day"
            ref={moment().isSame(day, "d") ? todayRef : null}
            onClick={() => {
              setSelectedDay(day.format("YYYY-MM-DD"));
              onDayClick(day);
            }}
            style={{
              ...(moment().isSame(day, "day") && {
                boxShadow: "0px 0px 15px 2px #fff",
                backgroundColor: "#e6f7ff",
                animation: "pulse 2s infinite",
                fontWeight: "bold",
                fontSize: "1.2em",
              }),
              ...(isSelectedDay && {
                border: "2px solid #6B81BF",
                backgroundColor: "#2E323F",
              }),
            }}
          >
            <div className="day-label">
              {day.format("D")}
              {moment().isSame(day, "d") && (
                <span className="today">Today</span>
              )}
            </div>
            {moment(day).format("YYYY-MM-DD") in visits &&
              visits[day.format("YYYY-MM-DD")].map((visit, index) => (
                <VisitTile key={index} visit={visit} />
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default Week;
