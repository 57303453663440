import { useState } from "react";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import NotesSection from "./common/NotesSection";

var RequestResumptionForm = ({
  form,
  context,
  errors,
  onSetForm
}) => {
  var { serviceName } = context;

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Resumption of Service
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <label>Resume after date</label>
        <ServiceCalendarDatePicker
          // minDate={dayjs(new Date())}
          open={open}
          setOpen={setOpen}
          onChange={(newValue) =>
            onSetForm({
              effectiveFrom: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Requested By"
        contactPlaceholder="Who requested the resumption?"
        dateLabel="Requested on"
        dateValue={form?.requestedOn}
        onChange={(newValue) =>
          onSetForm({
            requestedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

RequestResumptionForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    effectiveFrom: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    vendorLocationCode: PropTypes.string.isRequired,
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestResumptionForm;
