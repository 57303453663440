import { FC, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useOnScreen } from "service-calendar/utils";
import Week from "service-calendar/components/Week";
import Error from "service-calendar/components/Error";
import Loader from "../../components/shared/Loader";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Alert } from "@mui/material";

import { fetchAtDate, fetchMoreData } from "service-calendar/operations";
import { getLocationId } from "ticket/selectors";
import StatusBar from "service-calendar/components/StatusBar";
import _ from "lodash";

interface CalendarProps {
  calendarApi: any;
  onDayClick: any;
  fetchMore?: any;
  fetchInit?: any;
}

let Calendar: FC<CalendarProps> = ({
  calendarApi,
  onDayClick,
  fetchMore,
  fetchInit,
}) => {
  const {
    availableData,
    fetchingCurrent,
    fetchingPast,
    fetchingFuture,
    errorPast,
    errorFuture,
  } = calendarApi;
  const { startDate, endDate, visits } = availableData;
  const scrollRef = useRef<HTMLDivElement>();
  const todayCalendarRef = useRef<HTMLHRElement>();
  const isTodayVisible = useOnScreen(todayCalendarRef);
  const locationId = getLocationId();

  let selectedDay = null;

  const location = useLocation();
  const locationDate = location.pathname.split("/").slice(-1)[0];
  if (locationDate !== "calendar" && locationDate !== "") {
    selectedDay = moment(locationDate);
  }

  let weekStart = selectedDay
    ? moment(selectedDay).isoWeekday(1)
    : moment().isoWeekday(1);

  const [previousScrollPosition, setPreviousScrollPosition] = useState(null);
  const [selectedDayWeek, setSelectedDayWeek] = useState(undefined);

  const weeks = [];

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const scrollBottom = scrollHeight - scrollTop;
      if (scrollBottom < 2 * clientHeight) {
        fetchMoreDataFromServer("forward");
      } else if (scrollTop < 200) {
        setPreviousScrollPosition(weeks[0].key);
        fetchMoreDataFromServer("back");
      } else {
        setPreviousScrollPosition(null);
      }
    }
  };

  const jumpToToday = () => {
    if (todayCalendarRef.current) {
      const { top, bottom } = todayCalendarRef.current.getBoundingClientRect();
      const isVisible = top >= 0 && bottom <= window.innerHeight;

      if (!isVisible) {
        todayCalendarRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const fetchMoreDataFromServer = (direction) => {
    fetchMore(direction);
  };

  let date = moment(startDate).isoWeekday(1);
  while (date < moment(endDate)) {
    const selected = previousScrollPosition
      ? moment(previousScrollPosition)
          .subtract(7, "d")
          .isoWeekday(1)
          .format("YYYYMMDD") === date.format("YYYYMMDD")
      : date.format("YYYYMMDD") === weekStart.format("YYYYMMDD");
    weeks.push(
      <Week
        key={date.format("YYYY-MM-DD")}
        start={date}
        index={date.format("YYYY-MM-DD")}
        selected={selected}
        visits={visits}
        todayRef={todayCalendarRef}
        onDayClick={onDayClick}
        selectedDay={selectedDayWeek}
        setSelectedDay={setSelectedDayWeek}
      />
    );
    date = moment(date).add(7, "days");
  }
  // Fetch specific day data only once
  useEffect(() => {
    if (availableData.startDate === null && !fetchingCurrent) {
      fetchInit(selectedDay);
    }
  }, []);

  if (availableData.startDate === null || fetchingCurrent) {
    // fetchingCurrent
    return <Loader message="Loading..." />;
  }

  return (
    <div className="anenta-calendar">
      <StatusBar />
      <div id="calendar-holder">
        <div id="calendar">
          {!availableData.noData && (
            <div className="calendar-week first-week">
              <div className="calendar-week-label" />
              {_.range(7).map((index) => {
                const day = moment(date).add(index, "days");
                return (
                  <div key={index} className="calendar-day name">
                    <div className="day-label">{day.format("ddd")}</div>
                  </div>
                );
              })}
            </div>
          )}
          {availableData.noData ? (
            <Box padding={2}>
              <Alert severity="warning" sx={{ p: 1, fontSize: "14px" }}>
                There is no visit data available at this time
              </Alert>
            </Box>
          ) : (
            <div
              id="calendar-scroll"
              onScroll={() => onScroll()}
              ref={scrollRef}
            >
              {fetchingPast && <DataLoader />}
              {errorPast && <DataError />}
              {weeks}
              {errorFuture && <DataError />}
              {fetchingFuture && <DataLoader />}
            </div>
          )}
          {todayCalendarRef.current && (
            <button className="jump-to-today" onClick={() => jumpToToday()}>
              Jump to Today
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const DataLoader = () => (
  <div className="calendar-week">
    <div className="calendar-week-label" />
    <div className="calendar-day calendar-day-loader">
      <Loader />
    </div>
  </div>
);

const DataError = () => (
  <div className="calendar-week">
    <div className="calendar-week-label" />
    <div className="calendar-day calendar-day-loader">
      <Error />
    </div>
  </div>
);

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInit: (date) => {
      dispatch(fetchAtDate(date));
    },
    fetchMore: (direction) => {
      dispatch(fetchMoreData(direction));
    },
    //fetchDetailsData: (date) => {
    //dispatch(fetchDetails(date));
    //},
  };
};

Calendar = connect(mapStateToProps, mapDispatchToProps)(Calendar);

export default Calendar;
