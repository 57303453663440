import {
  Box,
  Card,
  CardContent,
  Collapse,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { formatDate } from "sharedUtils";
import ShowMoreDivider from "service/forms/common/ShowMoreDivider";
import React, { useContext } from "react";
import ServiceSuspensionRequestCardDetails from "./ServiceSuspensionRequestCardDetails";
import { ServiceSuspensionRequest } from "service/ticket/types";
import { ActionButtons } from "./AdhocVisitRequestCard";
import AppContext from "ticket/AppContext";
import Debugger from "service/forms/Debugger";
import CustomTimelineNotes from "service/forms/common/CustomTimeLineNotes";

interface ServiceSuspensionRequestCardProps {
  request: ServiceSuspensionRequest;
  isOpen: boolean;
  toggleOpen: () => void;
}

const ServiceSuspensionRequestCard: React.FC<
  ServiceSuspensionRequestCardProps
> = ({ request, isOpen, toggleOpen }) => {
  const { notes } = request;
  const { selectedContainer } = useContext(AppContext);

  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        position: "relative",
        ...(selectedContainer === request.id && {
          "& .MuiPaper-root": {
            border: "3px solid #6c8c89",
          },
        }),
      }}
      data-link-card={request.id}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          background: "#262B30",
          "& .MuiCardContent-root:last-child": { pb: 1 },
          "& *": { fontSize: "12px", color: "#fff" },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Service Suspension Request
              </Typography>
              <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
                on {formatDate(request?.requestedAt)}
              </Typography>
            </Box>
            <ActionButtons
              // TODO: Add actions and active
              actions={request?.actions}
              isActive={request?.isActive}
              request={request}
              linkSelected={selectedContainer === request.id}
            />
          </Stack>

          <ServiceSuspensionRequestCardDetails request={request} />
          <List sx={{ width: "100%" }} component="nav">
            <ShowMoreDivider toggleOpen={toggleOpen} isOpen={isOpen} />
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <CustomTimelineNotes notes={notes} />
            </Collapse>
          </List>
        </CardContent>
        <Debugger {...request} />
      </Card>
    </Box>
  );
};

export default ServiceSuspensionRequestCard;
