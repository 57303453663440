import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";

const CancelSuspensionForm = ({ form, context, errors, onSetForm }) => {
  const {
    serviceName,
    EffectiveFrom,
    WithImmediateEffect
  } = context;

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel Suspension Request
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 1 }}>
        <li>
        <strong>
          {WithImmediateEffect
            ? 'Vendor was requested to suspend service without final collection and effective from today'
            : `Vendor was requested to suspend service from ${formatDate(EffectiveFrom)}`
          }
        </strong>
        </li>
      </Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledOn}
        onChange={(newValue) =>
          onSetForm({
            cancelledOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default CancelSuspensionForm;
