import { api } from "./apiConfig";
import dayjs from "dayjs";

// Represents the detailed information about a visit
interface VisitDetail {
  status: string;
  date: string;
  title: string;
  vendorId: number;
  vendorLocationCodes: string[];
  vendorServiceCodes: string[];
  rescheduledFromDates: string[];
  rescheduledToDates: string[];
  planSources: string[];
  vendorName: string;
  planSourcesMap: {
    [key: string]: {
      type: string;
      messageGid: string;
      receivedOn: string;
      vendorLocationCode: string;
    };
  };
  type: string;
}

// Represents the structure of the response, mapping dates to arrays of visit details
interface VisitsResponse {
  visits: {
    [date: string]: VisitDetail[];
  };
}

// Define a type for the query parameters
interface GetLocationVisitsParams {
  locationId: string;
  date: string;
  vendorId: string;
  [key: string]: any; // For additional dynamic parameters
}

export const calendarApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLocationVisits: build.query<VisitsResponse, GetLocationVisitsParams>({
      query: ({ locationId, date, vendorId, ...rest }) => {
        // For future use with vendorId
        const queryObject = {
          locationId,
          date,
          vendorId,
          ...rest,
        };
        const isoDate = dayjs(date).format("YYYY-MM-DD");
        return {
          url: `admin/locations/${locationId}/calendar/details/${isoDate}.json`,
        };
      },
      providesTags: ["Visit"],
    }),
  }),
});

export const { useGetLocationVisitsQuery } = calendarApi;

export const {
  endpoints: { getLocationVisits },
} = calendarApi;

export default calendarApi;
