import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const ServiceSuspensionRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const {
    cancelledOn,
    date,
    notes,
    withImmediateEffect,
    effectiveFrom,
    reasonId,
  } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders = flatMap(stakeholderContacts, (x) => x);

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const immediateEffectText = withImmediateEffect
    ? "with immediate effect"
    : "";
  const effectiveFromText = effectiveFrom
    ? `Effective From: ${formatDate(effectiveFrom)}`
    : "";

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(request.name)}
        </Typography>
        <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
          on {formatDate(request?.requestedOn)} by{" "}
          {inputtedBy?.nameWithoutEmail}
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          mb: 1,
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
          "& td.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {cancelledOn ? (
          <Typography variant="h6">
            Service Suspension Request Cancelled on {formatDate(cancelledOn)}
          </Typography>
        ) : date ? (
          <Typography variant="h6">
            Confirmed by vendor on {formatDate(date)}
          </Typography>
        ) : (
          <Typography variant="h6">
            Service Suspension Requested on {formatDate(request?.requestedOn)}
          </Typography>
        )}
        <Typography variant="h6">
          Vendor has been requested to suspend service {immediateEffectText}
        </Typography>

        {reasonId && (
          <Typography variant="h6">Reason: {startCase(reasonId)}</Typography>
        )}

        {effectiveFrom && (
          <Typography variant="h6">{effectiveFromText}</Typography>
        )}

        {notes && <Typography variant="h6">{notes}</Typography>}
      </Collapse>
    </>
  );
};

export default ServiceSuspensionRequestLog;
