import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StreamIcon } from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import { getVendorId } from "ticket/selectors";

const VlcTable = ({ vendorLocationAccounts }) => {
  return (
    <TableContainer
      sx={{
        "& .MuiTableCell-root": {
          fontSize: "11px",
          fontWeight: "inherit",
          color: "inherit",
        },
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              fontWeight: "bold",
              color: "#828B98",
              border: "1px solid transparent",
            }}
          >
            <TableCell>VLC</TableCell>
            <TableCell>First Billed</TableCell>
            <TableCell>Last Billed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableRow-root": {
              borderTop: "1px solid #F1F1F5",
            },
          }}
        >
          {vendorLocationAccounts?.map((item) => (
            <TableRow key={item.vendorLocationCode}>
              <TableCell>
                <Link
                  target="_blank"
                  href={`/admin/vendors/${item.vendorId}/location_mappings/${encodeURIComponent(
                    item.vendorLocationCode,
                  )}`}
                  underline="none"
                  sx={{ color: "#3BAFDA !important" }}
                >
                  {item.vendorLocationCode}
                </Link>
                {item?.streamUrl && (
                  <IconButton
                    onClick={() => window.open(item?.streamUrl, "_blank")}
                    sx={{ fontSize: "10px" }}
                  >
                    <StreamIcon sx={{ fontSize: "15px" }} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>{formatDate(item.firstBilledOn)}</TableCell>
              <TableCell>{formatDate(item.lastBilledOn)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VlcTable;
