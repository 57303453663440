import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, ListItemButton } from "@mui/material";
import { groupBy, map, orderBy, union } from "lodash";
import { useState } from "react";
import {
  AdhocVisitRequest,
  StockDeliveryRequest,
  SpecChangeRequest,
  VisitFrequencyChangeRequest,
  ServiceSuspensionRequest,
  ServiceTerminationRequest,
  ServiceResumptionRequest,
} from "service/ticket/types";
import { getTicketId } from "ticket/selectors";
import AdhocVisitRequestCard from "./AdhocVisitRequestCard";
import StockDeliveryRequestCard from "./StockDeliveryCard";
import SpecChangeRequestCard from "./SpecChangeRequestCard";
import VisitFrequencyChangeRequestCard from "./VisitFrequencyChangeRequestCard";
import ServiceSuspensionRequestCard from "./ServiceSuspensionRequestCard";
import ServiceTerminationRequestCard from "./ServiceTerminationRequestCard";
import ServiceResumptionRequestCard from "./ServiceResumptionRequestCard";

type AllRequests =
  | AdhocVisitRequest
  | StockDeliveryRequest
  | SpecChangeRequest
  | VisitFrequencyChangeRequest
  | ServiceSuspensionRequest
  | ServiceResumptionRequest
  | ServiceTerminationRequest;

const AllRequestTab = ({
  adhocVisitRequests,
  stockDeliveryRequests,
  specChangeRequests,
  visitFrequencyChangeRequests,
  serviceSuspensionRequests,
  serviceResumptionRequests,
  serviceTerminationRequests,
}) => {
  const ticketId = getTicketId();
  const allRequests: AllRequests[] = union(
    adhocVisitRequests,
    stockDeliveryRequests,
    specChangeRequests,
    visitFrequencyChangeRequests,
    serviceSuspensionRequests,
    serviceResumptionRequests,
    serviceTerminationRequests
  );

  const [openStates, setOpenStates] = useState(() => {
    let initialStates = {};
    if (allRequests) {
      // Set the initial state for the group of the current ticket to be expanded
      initialStates[`group-${ticketId}`] = true;
      allRequests.forEach((request) => {
        initialStates[request.id] = false;
      });
    }
    return initialStates;
  });

  const handleClick = (id: string) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const groupedRequests = groupBy(
    orderBy(allRequests, ["requestedAt"], ["desc"]),
    (request) => {
      return request?.linkedTicketIds[0];
    }
  );

  const renderGroup = (requests, currentTicketId) => (
    <Box key={currentTicketId}>
      <ListItemButton
        onClick={() => handleClick(`group-${currentTicketId}`)}
        sx={{ fontSize: "12px", fontWeight: "bold", pb: 1, color: "#fff" }}
      >
        {currentTicketId === ticketId
          ? "Requests on this Ticket"
          : `Ticket ID: ${currentTicketId}`}
        {openStates[`group-${currentTicketId}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={openStates[`group-${currentTicketId}`]}
        timeout="auto"
        unmountOnExit
      >
        {requests.map((request) => {
          const RequestCardComponent = {
            AdhocVisitRequest: AdhocVisitRequestCard,
            StockDeliveryRequest: StockDeliveryRequestCard,
            SpecChangeRequest: SpecChangeRequestCard,
            VisitFrequencyChangeRequest: VisitFrequencyChangeRequestCard,
            ServiceSuspensionRequest: ServiceSuspensionRequestCard,
            ServiceTerminationRequest: ServiceTerminationRequestCard,
            ServiceResumptionRequest: ServiceResumptionRequestCard,
          }[request.type];
          return (
            <RequestCardComponent
              key={request.id}
              request={request}
              isOpen={openStates[request.id]}
              toggleOpen={() => handleClick(request.id)}
            />
          );
        })}
      </Collapse>
    </Box>
  );

  return (
    <>
      {/* Render the group with the specific ticketId first if it exists */}
      {groupedRequests[ticketId] &&
        renderGroup(groupedRequests[ticketId], ticketId)}

      {/* Render other groups */}
      {map(groupedRequests, (requests, currentTicketId) => {
        if (currentTicketId !== ticketId.toString()) {
          return renderGroup(requests, currentTicketId);
        }
      })}
    </>
  );
};

export default AllRequestTab;
