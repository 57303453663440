import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { PickersDayProps } from "@mui/lab";
import { Tooltip } from "@mui/material";
import _, { startCase } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";

const datePickerStyles = {
  calendarHeader: {
    sx: {
      fontSize: "12px",
      "& .MuiPickersCalendarHeader-labelContainer": { fontSize: "12px" },
    },
  },
  textField: {
    sx: {
      fontSize: "12px",
      width: "100%",
      "& .MuiInputBase-root": { fontSize: "12px" },
      "& .MuiOutlinedInput-input": {
        padding: "8.5px 14px",
      },
    },
  },
  layout: {
    sx: { ".MuiDayCalendar-weekDayLabel": { fontSize: "12px" } },
  },
};

interface VisitDay {
  status: string;
  date: Dayjs;
}

const visitDayStyles = {
  failure: { border: "2px solid #EA3223" },
  success: { border: "2px solid #459539" },
  conflicting: { border: "2px solid #EF8635" },
  unknown: { border: "2px solid #3A21EF" },
  planned: { border: "2px solid #F5BD43" },
  assumed: { border: "2px solid #8F3FCD" },
  cancelled: { border: "2px solid #bebebe" },
};

const statusVisit = {
  failure: "Failure Reported",
  success: "Success Reported",
  conflicting: "Conflicting Reports",
  unknown: "Status Unknown",
  planned: "Service Planned",
  assumed: "Service Assumed",
  cancelled: "Service Cancelled",
};

function ServerDay(props: PickersDayProps<Dayjs> & { visitDays?: VisitDay[] }) {
  const {
    visitDays = [],
    day,
    outsideCurrentMonth,
    isFirstVisibleCell,
    isLastVisibleCell,
    ...other
  } = props;

  // Find a visitDay that matches the current day
  const visitDay = _.find(
    visitDays,
    ({ date }) => dayjs.isDayjs(date) && day.isSame(date, "day"),
  );

  // Apply matched styles if visitDay exists
  const matchedStyles = visitDay ? visitDayStyles[visitDay.status] : {};
  const tooltipTitle = visitDay ? startCase(statusVisit[visitDay.status]) : "";

  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <PickersDay
        {...other}
        onDaySelect={other.onDaySelect}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        isFirstVisibleCell={isFirstVisibleCell}
        isLastVisibleCell={isLastVisibleCell}
        sx={{
          ...matchedStyles,
          fontSize: "12px",
        }}
      />
    </Tooltip>
  );
}

const ServiceCalendarDatePicker = ({
  open,
  setOpen,
  onChange,
  maxDate = null,
  visitDays = [],
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        slots={{ day: ServerDay }}
        slotProps={{
          day: {
            visitDays,
          } as any,
          calendarHeader: datePickerStyles.calendarHeader,
          textField: {
            ...datePickerStyles.textField,
            onClick: () => setOpen(true),
          },
          layout: datePickerStyles.layout,
        }}
        open={open}
        onChange={onChange}
        onClose={() => setOpen(false)}
        maxDate={maxDate}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default ServiceCalendarDatePicker;
