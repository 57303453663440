import { useCallback, useState } from "react";

export const useDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeChannel, setActiveChannel] = useState();

  const toggleDrawer = useCallback(() => setIsDrawerOpen((prev) => !prev), []);
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setActiveChannel(null);
  }, []);

  const toggleDrawerAndSetChannel = useCallback(
    (newChannel) => {
      if (activeChannel === newChannel && isDrawerOpen) {
        closeDrawer();
      } else {
        toggleDrawer();
        setActiveChannel(newChannel);
        setIsDrawerOpen(true);
      }
    },
    [activeChannel, isDrawerOpen, closeDrawer, toggleDrawer],
  );

  return {
    isDrawerOpen,
    closeDrawer,
    activeChannel,
    toggleDrawerAndSetChannel,
    setIsDrawerOpen,
    setActiveChannel,
  };
};
