import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TagIcon from "@mui/icons-material/Tag";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodashExtended";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ServiceActionMenu, ServiceLog } from "serviceComponents";
import { DropdownSelect, Link, ShowDate } from "sharedComponents";
import { useBinFetch } from "sharedUtils";
import { useTicket } from "../../apps/ticket/selectors";
import "./service-box.scss";
// import { MenuActions } from "../../apps/ticket/features/ticket/actions-menu/MenuActions";
import { map, toArray } from "lodash";

const VisitFrequencyRow = ({ service }) => {
  let requestedVisitValue;

  if (
    _.isPresent(service?.requestedVisitPlanName) &&
    service?.requestedVisitPlanName != service?.visitPlanName
  ) {
    requestedVisitValue = `Expecting to be ${service?.requestedVisitPlanName}`
  }

  return (
    <React.Fragment>
      {requestedVisitValue && requestedVisitValue}
      Collected: {service?.visitPlanName || "Unknown"}
    </React.Fragment>
  );
};

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
}

const StatusRow = ({ service }) => {
  const isClosed =
    service?.status === "account_closed" &&
    service?.vendorAccountClosedMonthName;

  return (
    <React.Fragment>
      {isClosed && (
        <Typography sx={{ color: "red" }}>
          {service.vendorAccountClosedMonthName}
        </Typography>
      )}
      Status: {humanize(service?.status)}
    </React.Fragment>
  );
};

let Specification = ({
  service,
  //binTypes,
  //binGroups
}) => {
  let { specifications = {}, billingHistoryUnitAverages = {} } = service;

  const allCodes = _([
    _.map(specifications, "serviceCode"),
    _.map(billingHistoryUnitAverages, "code"),
  ])
    .flatten()
    .uniq()
    .value();

  const [binTypes, binGroups, loading, hasError] = useBinFetch(allCodes);

  if (loading) {
    return null;
  }

  const codes = _.sortBy(allCodes, (code) => {
    let { capacityMcl = 0, groupName = "X" } = binTypes[code] || {};
    return [groupName, capacityMcl];
  });

  let rows = _.map(codes, (code) => {
    let { serviceCode, quantity, visitActionId } = specifications[code] || {};

    let { quantity: averageQuantity } = billingHistoryUnitAverages[code] || {};

    const binType = binTypes[code] || {};
    const bg = binGroups[binType.binGroupId];

    return (
      <TableRow key={code}>
        <TableCell>
          <BinDot binGroup={bg} />
        </TableCell>
        <TableCell>{averageQuantity}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{binType.name}</TableCell>
        <TableCell>{visitActionId}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer
      sx={{
        marginY: 2,
        "& .MuiTableCell-root": {
          fontSize: "11px",
          fontWeight: "inherit",
          color: "inherit",
        },
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow
            sx={{
              fontWeight: "bold",
              color: "#828B98",
              border: "1px solid transparent",
            }}
          >
            <TableCell>Type</TableCell>
            <TableCell>Avg Qty</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-root": {
              border: "1px solid #F1F1F5",
            },
          }}
        >
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

let ServiceBox = ({ service, onSelectAction }) => {
  const formatDate = (date) => new Date(date).toLocaleDateString("en-GB");

  const lastVisit = service?.lastVisit?.date;
  const nextVisit = service?.nextVisit?.date;
  const vendorComments = service?.vendorComments;
  const specifications = service?.specifications;
  const billingHistoryUnitAverages = service?.billingHistoryUnitAverages;

  const actions = service?.actions;
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(true);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const allCodes = _([
    _.map(specifications, "serviceCode"),
    _.map(billingHistoryUnitAverages, "code"),
  ])
    .flatten()
    .uniq()
    .value();

  const { binGroups } = useBinFetch(allCodes);
  const availableBinGroups = map(binGroups, (b) => {
    return <BinDot binGroup={b} />;
  });

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: 2,
        paddingBottom: 0,
        position: "sticky",
        top: 80,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {service?.name} - {service?.vendorName}
        </Typography>
        <div style={{ display: "flex" }}>
          <AddIcon
            onClick={handleOpenMenu}
            sx={{ cursor: "pointer", color: "blue", fontSize: "35px" }}
          />
          <TagIcon
            // onClick={onSelectAction}
            sx={{ cursor: "pointer", color: "green", fontSize: "35px" }}
          />
        </div>

        <MenuActions
          menuOptions={toArray(actions)}
          anchorEl={anchorEl}
          handleClose={handleCloseMenu}
          onMenuItemClick={onSelectAction}
        />
      </Box>

      <Box sx={{ color: "#828B98", marginTop: 1 }}>
        <VisitFrequencyRow service={service} />
        <div>
          Last Collection <ShowDate date={lastVisit?.date} />
          {lastVisit?.status && ` (${_.capitalize(lastVisit.status)})`}
        </div>
        <div>
          Next Visit <ShowDate date={nextVisit?.date} />
          {nextVisit?.status && ` (${_.capitalize(nextVisit.status)})`}
        </div>
        <StatusRow service={service} />
        <div>Vendor Comment: {_.join(vendorComments, ", ")}</div>
      </Box>

      <TableContainer
        sx={{
          marginY: 2,
          mx: -2,
          width: "auto",
          "& .MuiTableCell-root": {
            fontSize: "11px",
            fontWeight: "inherit",
            color: "inherit",
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                fontWeight: "bold",
                color: "#828B98",
                border: "1px solid transparent",
              }}
            >
              <TableCell>VLC</TableCell>
              <TableCell>First Billed</TableCell>
              <TableCell>Last Billed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                border: "1px solid #F1F1F5",
              },
            }}
          >
            {service?.vendorLocationAccounts?.map((item) => (
              <TableRow key={item.vendorLocationCode}>
                <TableCell>{item.vendorLocationCode}</TableCell>
                <TableCell>{formatDate(item.firstBilledOn)}</TableCell>
                <TableCell>{formatDate(item.lastBilledOn)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Accordion
        expanded={expanded}
        onChange={(e, _expanded) => setExpanded(_expanded)}
        disableGutters
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          mx: -2,
          mb: -2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: "35px" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              gap: 2,
            },
          }}
        >
          {!expanded && (
            <>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Bin Groups
              </Typography>
              {availableBinGroups}
            </>
          )}
        </AccordionSummary>

        <AccordionDetails sx={{ mx: -2 }}>
          <Specification service={service} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

ServiceBox.propTypes = {
  service: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    nextVisit: PropTypes.shape({
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
    lastVisit: PropTypes.shape({
      date: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
    specifications: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        visitActionId: PropTypes.string,
        //visitActionId: PropTypes.string.isRequired,
        serviceCode: PropTypes.string.isRequired,
      })
    ),
    billingHistoryUnitAverages: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.integer,
        code: PropTypes.string.isRequired,
        serviceCode: PropTypes.string,
      })
    ),
    vendorServiceCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  onSelectAction: PropTypes.func,
};

export default ServiceBox;
