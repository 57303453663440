import BinDot from "service/BinDot";
import {
  CollectionQuantitySelector,
  HeaderDetails,
  QuantitySelector,
} from "./QuantitySelector";
import VisitActionSelector from "../VisitActionSelector.jsx";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";

const CollectionUnitsTable = ({
  headerDetailsHandler,
  collectionUnits,
  deliveryUnits,
  binTypes,
  binGroups,
  updateCollectionQuantity,
  updateCollectionCollectAll,
  deliverAdditionalStock,
  updateDeliveryQuantity,
  updateVisitActionId,
  removeUnit,
  availableCollectionOptions,
}) => {
  const collectionRows = _.map(
    collectionUnits,
    ({ serviceCode, collectAll, visitActionId, quantity: collectionQty }) => {
      const bin = binTypes[serviceCode];

      const { quantity: deliveryQty } = deliveryUnits[serviceCode];
      // Uncomment this to load value from specifications
      // const quantity = specifications[serviceCode]?.quantity;

      const binGroup = binGroups[bin?.binGroupId];
      return (
        <TableRow key={serviceCode}>
          <TableCell scope="row">
            <BinDot binGroup={binGroup} tooltipName={true} />
          </TableCell>
          <TableCell>
            {bin?.imageUrl && (
              <img
                src={bin?.imageUrl}
                alt={bin?.name}
                style={{ marginRight: "10px", width: "30px", height: "30px" }}
              />
            )}
            {bin?.name}
          </TableCell>
          <TableCell>{serviceCode}</TableCell>
          <TableCell>
            {visitActionId != "do_not_collect" ? (
              <CollectionQuantitySelector
                quantity={collectionQty}
                collectAll={collectAll}
                onChangeQuantity={(q) =>
                  updateCollectionQuantity(Number(q), serviceCode)
                }
                onChangeCollectAll={(c) =>
                  updateCollectionCollectAll(c, serviceCode)
                }
              />
            ) : (
              "-"
            )}
          </TableCell>
          {deliverAdditionalStock && (
            <TableCell style={{ width: "10%" }}>
              <QuantitySelector
                quantity={deliveryQty}
                onChange={(q) => updateDeliveryQuantity(q, serviceCode)}
              />
            </TableCell>
          )}
          <TableCell style={{ width: "30%" }}>
            <VisitActionSelector
              visitActionId={visitActionId}
              defaultValue="collect_and_replace"
              possibleVisitActions={bin?.possibleVisitActions}
              onChange={(vaId) => updateVisitActionId(vaId, serviceCode)}
            />
          </TableCell>

          <TableCell>
            <IconButton
              sx={{
                m: 0,
                p: 0,
              }}
            >
              <RemoveIcon
                onClick={(_e) => removeUnit(serviceCode)}
                sx={{
                  cursor: "pointer",
                  fontSize: "35px",
                  color: "red",
                  display: "block",
                  margin: "auto",
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    },
  );
  return (
    <>
      <HeaderDetails
        onClick={headerDetailsHandler}
        noOptions={availableCollectionOptions.length === 0}
      />
      <TableContainer
        sx={{
          marginY: 1,
          overflow: "hidden",
          "& .MuiTableCell-root": {
            fontSize: "11px",
            fontWeight: "inherit",
            color: "inherit",
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                textWrap: "nowrap",
                fontWeight: "bold",
                color: "#828B98",
                border: "1px solid transparent",
              }}
            >
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Quantity</TableCell>
              {deliverAdditionalStock && <TableCell>Deliver # Units</TableCell>}
              <TableCell>Visit Action</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                borderTop: "1px solid #F1F1F5",
                borderBottom: "1px solid #F1F1F5",
                padding: 0,
              },
            }}
          >
            {collectionRows}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CollectionUnitsTable;
