import "react-infinite-calendar/styles.css";
import "./styles.scss";
import { ModelErrors } from "sharedComponents";
import { Grid, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import Debugger from "./Debugger";
import VisitFailureChips from "./common/VisitFailureChips";
import PartiallyCollectedTableBins from "./common/PartiallyCollectedTableBins";
import dayjs from "dayjs";
import { useState } from "react";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import NotesSection from "./common/NotesSection";

const ReportVisitFailureForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);
  const { collectionResultSlug, deliveryResultSlug } = form;

  const {
    serviceName,
    deliveryResults,
    collectionResults,
    inUseBinGroups,
    lastVisits,
    visitPlanName,
  } = context;

  const collectionHandler = (slug) =>
    onSetForm({
      collectionResultSlug: slug,
    });

  const deliveryHandler = (slug) =>
    onSetForm({
      deliveryResultSlug: slug,
    });

  const visitDays = lastVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Report Visit Failure
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          my: 1,
        }}
      >
        <Grid
          item
          xs
          sx={{
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& .MuiTextField-root": { width: "fit-content" },
          }}
        >
          <Typography>Failure Date</Typography>
          <ServiceCalendarDatePicker
            open={open}
            visitDays={visitDays}
            setOpen={setOpen}
            // maxDate={dayjs(new Date())}
            onChange={(newValue) =>
              onSetForm({
                failureDate: newValue.format("DD/MM/YYYY"),
              })
            }
          />
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& > *": {
              flex: 1,
            },
          }}
        >
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste collected
            </Typography>
            <VisitFailureChips
              options={collectionResults}
              selectedSlug={collectionResultSlug}
              onChange={collectionHandler}
              showReasonFailure={false}
            />
          </div>
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste delivered
            </Typography>
            <VisitFailureChips
              options={deliveryResults}
              selectedSlug={deliveryResultSlug}
              onChange={deliveryHandler}
              showReasonFailure={false}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          {!isEmpty(inUseBinGroups) &&
            collectionResultSlug == "partial_failure" && (
              <PartiallyCollectedTableBins
                inUseBinGroups={inUseBinGroups}
                onSetForm={onSetForm}
              />
            )}
        </Grid>
      </Grid>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Reported By"
        contactPlaceholder="Who reported the failure?"
        dateLabel="Reported On"
        dateValue={form?.reportedOn}
        onChange={(newValue) =>
          onSetForm({
            reportedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <ModelErrors errors={errors} />
      <Debugger {...form} />
    </div>
  );
};
//<Debugger show {...form} />

// TODO: Add typing
// ReportVisitFailureForm.propTypes = {
//   form: PropTypes.shape({
//     notes: PropTypes.string,
//     failureDate: PropTypes.string,
//   }),
//   errors: PropTypes.object,
//   context: PropTypes.shape({
//     sourceIsUnknown: PropTypes.bool.isRequired,
//     serviceName: PropTypes.string.isRequired,
//     vendorLocationCode: PropTypes.string.isRequired,
//     collectionResults: PropTypes.arrayOf(
//       PropTypes.shape({
//         name: PropTypes.string.isRequired,
//         slug: PropTypes.string.isRequired,
//       })
//     ),
//     deliveryResults: PropTypes.arrayOf(
//       PropTypes.shape({
//         name: PropTypes.string.isRequired,
//         slug: PropTypes.string.isRequired,
//       })
//     ),
//     currentSpecificationBinGroups: PropTypes.objectOf(
//       PropTypes.shape({
//         id: PropTypes.number.isRequired,
//         name: PropTypes.string.isRequired,
//         legendFillColor: PropTypes.string.isRequired,
//         legendBorderColor: PropTypes.string.isRequired,
//       })
//     ),
//   }),
//   onSetForm: PropTypes.func.isRequired,
// };

export default ReportVisitFailureForm;
