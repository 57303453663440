import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: (headers) => {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");
    if (csrfToken) {
      headers.set("X-CSRF-Token", csrfToken);
      headers.set("Accept", "application/json");
    }
    return headers;
  },
});

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: "/",
      prepareHeaders: (headers) => {
        headers.set("Accept", "application/json");
        headers.set("Cache-Control", "no-cache");
        headers.set("Pragma", "no-cache");
        headers.set("Expires", "0");
        const csrfToken = document
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content");
        if (csrfToken) {
          headers.set("X-CSRF-Token", csrfToken);
          headers.set("Accept", "application/json");
        }
        return headers;
      },
    })(args, api, extraOptions);

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    if ([401, 403, 404, 422, 500].includes(result.error?.status)) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 0,
  }
);

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "api",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: staggeredBaseQueryWithBailOut,
  //baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "BinType",
    "BinGroup",
    "Ticket",
    "TicketReplies",
    "Service",
    "FailedVisitReport",
    "AdhocVisitRequests",
    "StockDeliveryRequests",
    "RemediationCases",
    "Visit",
    "VendorServiceMapping",
    "ServiceSuspensionRequests",
    "ServiceTerminationRequests",
    "ServiceResumptionRequests",
    "SpecChangeRequests",
    "VisitFrequencyChangeRequests",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({}),
});
