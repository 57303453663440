import { Box, Link } from "@mui/material";
import dayjs from "dayjs";
import { getVendorId } from "ticket/selectors";
import VendorAccountLink from "./VendorAccountLink";

// Define interface for VendorLocationAccounts within the payload
export interface VendorLocationAccount {
  lastBilledOn: string;
  firstBilledOn: string;
  vendorLocationCode: string;
}

// Define interface for VendorAccount within the payload
export interface VendorAccount {
  name: string;
  id: number;
  wrongCommissioner: boolean;
  vendorServiceId: number;
  transitionOn: string;
  closed: boolean;
  nameWithoutClosed: string;
}

// Define the top-level interface that includes both vendorAccount and vendorLocationAccounts
export interface ServiceMappingInfoProps {
  firstDate: string; // Added to match the payload
  vendorAccount: VendorAccount;
  vendorLocationAccounts: VendorLocationAccount[];
  oldVLA: boolean;
  openVLC: boolean;
  vendorAccountClosedMonthName: string;
}

const ServiceMappingInfo: React.FC<ServiceMappingInfoProps> = ({
  vendorAccount,
  vendorLocationAccounts,
  oldVLA,
  openVLC,
  vendorAccountClosedMonthName,
}) => {
  const vendorId = getVendorId();
  return (
    <Box
      sx={{
        mx: 2,
        pb: 1,
        "& *": {
          fontSize: "12px",
        },
      }}
    >
      <VendorAccountLink
        vendorAccount={vendorAccount}
        vendorAccountClosedMonthName={vendorAccountClosedMonthName}
      />
      {vendorAccount?.transitionOn && (
        <span className="text-muted">
          &nbsp;
          from {dayjs(vendorAccount.transitionOn).format("MMMM YYYY")}
        </span>
      )}
      {vendorAccount?.wrongCommissioner && (
        <div>
          <strong className={oldVLA ? "text-muted" : "text-danger"}>

            &nbsp;
            Wrong Commissioner
          </strong>
        </div>
      )}
      {!openVLC && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "8px",
            rowGap: "4px",
          }}
        >
          {vendorLocationAccounts?.map((vla) => (
            <Link
              key={vla.vendorLocationCode}
              target="_blank"
              href={`/admin/vendors/${vendorId}/location_mappings/${encodeURIComponent(
                vla?.vendorLocationCode,
              )}`}
              underline="none"
              sx={{ color: "#777", fontSize: "11px" }}
            >
              {vla?.vendorLocationCode}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ServiceMappingInfo;
