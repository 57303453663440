import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import SpecChangeRequestCardDetails from "service/report-request-box/SpecChangeRequestCardDetails";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const SpecChangeRequestLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { cancelledOn, date, notes } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders = flatMap(stakeholderContacts, (x) => x);

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  // TODO: Handle accept spec change when implemented
  const isConfirmed = false;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(request.name)}
        </Typography>
        <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
          on {formatDate(request?.requestedOn)} by{" "}
          {inputtedBy?.nameWithoutEmail}
        </Typography>
        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
          "& td.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {cancelledOn ? (
          <Typography variant="h6" sx={{ mb: 1 }}>
            Spec Change Request Cancelled on {formatDate(cancelledOn)}
          </Typography>
        ) : isConfirmed ? (
          <Typography variant="h6" sx={{ mb: 1 }}>
            Confirmed by vendor on {formatDate(date)}
          </Typography>
        ) : (
          <Typography variant="h6">Spec Change Requested</Typography>
        )}

        {request?.data?.specifications && (
          <SpecChangeRequestCardDetails request={request?.data} />
        )}
        {notes && (
          <Typography variant="h6" sx={{ my: 1 }}>
            {notes}
          </Typography>
        )}
      </Collapse>
    </>
  );
};

export default SpecChangeRequestLog;
