import {
  configureStore,
  ConfigureStoreOptions,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from "api/apiConfig";
import { createReduxHistoryContext } from "redux-first-history";
import { createHashHistory } from "history";
import { calendarApiReducer } from "service-calendar/reducer";
import visitDateReducer from "api/visitDateSlice";

interface RootStateInitial {
  ticketId: any;
  calendarApi: any;
  //ticketId: string;
  //serviceIds: string[];
  //locationId: string;
  //vendorId: string;
  //api: any; // Replace 'any' with the actual type if you have it
}

const initialHistory = createHashHistory();
// initialHistory.push(window.location.pathname + window.location.search);

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: initialHistory,
  });

const rootReducer = (state: any, action: Action): RootStateInitial => {
  return {
    ...state,
    calendarApi: calendarApiReducer(state.calendarApi, action),
    router: routerReducer(state.router, action),
    [api.reducerPath]: api.reducer(state[api.reducerPath], action),
    visitDate: visitDateReducer(state.visitDate, action),
  };
};

const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined,
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware).concat(routerMiddleware),
    ...options,
  });
};

export const createStoreWithHistory = (preloadedState) => {
  const store = createStore({ preloadedState });
  const history = createReduxHistory(store);

  store.dispatch({ type: "@@redux-first-history/INIT" });

  return { store, history };
};

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof createStore>["getState"];
export const useTypedSelector: TypedUseSelectorHook<RootStateInitial> =
  useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateInitial,
  unknown,
  Action<string>
>;
