import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTicket } from "ticket/selectors";

const AgentSelect = ({ assignedAgentId, setAssignedAgentId }) => {
  const ticket = useTicket();

  const onChange = (e) => {
    setAssignedAgentId(e.target.value);
  };

  return (
    <Select
      value={assignedAgentId}
      onChange={onChange}
      displayEmpty
      size="small"
      renderValue={(selected) => {
        if (selected === "" || selected === "unassigned") {
          return "Unassigned";
        }
        const selectedAgent = ticket?.agentsMap?.find(
          (agent: any) => agent.id === selected,
        );
        return selectedAgent ? selectedAgent.name : "Unassigned";
      }}
      sx={{
        borderRadius: 0,
        textWrap: "nowrap",
        background: "#fff",
      }}
    >
      <MenuItem value={""}>Unassigned</MenuItem>
      {ticket?.agentsMap?.map((agent: any) => (
        <MenuItem key={agent.id} value={agent.id}>
          {agent.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AgentSelect;
