import { Box, Tooltip, Typography } from "@mui/material";
import OverflowTooltip from "service/forms/common/OverflowTooltip";
import { formatDate } from "sharedUtils";
import { humanize } from "./utils";

export const StatusRow = ({ service }) => {
  const serviceStatus =
    service?.status === "requested_to_terminate"
      ? "Terminating"
      : service?.status === "requested_to_suspend"
        ? "Suspending"
        : service?.status;

    const date = service?.terminationDate
      ? service?.terminationDate
      : service?.suspensionDate
        ? service?.suspensionDate
        : service?.finalCollectionRequiredBefore || service?.stopRequestedOn;

  return (
    <OverflowTooltip>
      <Typography>
        Status:{" "}
        <span style={{ color: getColor(service?.status) }}>
          {humanize(serviceStatus)}
        </span>
        {date && <p style={{ color: "#828B98" }}> on {formatDate(date)}</p>}
      </Typography>
    </OverflowTooltip>
  );
};

const getColor = (status) => {
  const colors = {
    running: "green",
    terminated: "red",
    suspended: "red",
    requested_to_suspend: "orange",
    requested_to_terminate: "orange",
  };
  return colors[status] || "inherit";
};
