import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewListIcon from "@mui/icons-material/ViewList";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import dayjs from "dayjs";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "service/Calendar";
import {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "service/report-request-box/MainTabs";
import { formatDate } from "sharedUtils";
import Loader from "../../../components/shared/Loader";
import { fetchAtDate } from "../operations";
import Error from "./Error";
import ListView from "./ListView";
import SideBar from "./SideBar";

interface AppProps {
  locationId: string;
  calendarApi: any;
  fetchSpecific: any;
}

let App: FC<AppProps> = ({ locationId, calendarApi, fetchSpecific }) => {
  const { availableData, fetchingCurrent, errorCurrent } = calendarApi;

  const [serviceBoxTab, setServiceBoxTab] = useState(0);
  const [visitTab, setVisitTab] = useState(undefined);
  const [calendarView, setCalendarView] = useState("calendar");
  const visitDate = useSelector((state) => state?.visitDate?.value);

  let selectedDay = null;
  const location = useLocation();
  const locationDate = location.pathname.split("/").slice(-1)[0];
  if (locationDate !== "calendar" && locationDate !== "") {
    selectedDay = moment(locationDate);
  }
  const history = useHistory();
  const [, setShowingDetails] = useState(selectedDay ? true : false);

  useEffect(() => {
    if (visitDate) {
      selectedDay = visitDate;
      history.push(`/${visitDate}`);
    }
  }, [visitDate]);

  // Fetch specific day data only once
  useEffect(() => {
    if (availableData.startDate === null && !fetchingCurrent) {
      fetchSpecific(selectedDay);
    }
  }, []);

  if (errorCurrent) {
    return <Error />;
  }

  if (availableData.startDate === null || fetchingCurrent) {
    // fetchingCurrent
    return <Loader message="Loading..." />;
  }

  const onDayClick = (day) => {
    setShowingDetails(true);
    selectedDay = day;
    setVisitTab(formatDate(day));
    setServiceBoxTab(1);
    history.push(
      //`/admin/locations/${locationId}/calendar/${day.format("YYYY-MM-DD")}`
      `/${day.format("YYYY-MM-DD")}`,
    );
  };

  const handleCalendarView = (
    _event: React.MouseEvent<HTMLElement>,
    newCalendarView: string | null,
  ) => {
    if (calendarView !== newCalendarView && newCalendarView !== null) {
      setCalendarView(newCalendarView);
      if (newCalendarView === "list") {
        setServiceBoxTab(0);
        setVisitTab(undefined);
      }
    }
  };

  return (
    <div className="anenta-calendar">
      <div id="calendar-layout-holder">
        <div id="calendar-holder">
          <StyledTabs value={0}>
            <StyledTab label="Visit Activity" />
          </StyledTabs>
          <TabPanel value={0} index={0} sx={{ position: "relative" }}>
            <ToggleButtonGroup
              value={calendarView}
              exclusive
              onChange={handleCalendarView}
              sx={{
                position: "absolute",
                right: 0,
                background: "#2C3237",
                "& *": { color: "#fff" },
              }}
            >
              <ToggleButton value="calendar">
                <CalendarMonthIcon />
              </ToggleButton>
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {calendarView === "calendar" && (
              <Calendar calendarApi={calendarApi} onDayClick={onDayClick} />
            )}
            {calendarView === "list" && <ListView calendarApi={calendarApi} />}
          </TabPanel>
        </div>
        <div id="sidebar-holder">
          <SideBar
            selectedDay={dayjs(selectedDay)}
            locationId={locationId}
            serviceBoxTab={serviceBoxTab}
            setServiceBoxTab={setServiceBoxTab}
            visitTab={visitTab}
            setVisitTab={setVisitTab}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locationId: state.locationId,
    calendarApi: state.calendarApi,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSpecific: (date) => {
      dispatch(fetchAtDate(date));
    },
  };
};

App = connect(mapStateToProps, mapDispatchToProps)(App);

export default App;
