import moment from "moment";
import { map } from "lodash";
import { useGetLocationVisitsQuery } from "api/calendar";
import VisitBox from "service/VisitBox";
import { FC } from "react";
import { Dayjs } from "dayjs";
import { LocationServiceVendor } from "api/services";
import { Alert, Box } from "@mui/material";

interface VisitDetailsProps {
  selectedDay: moment.Moment | Dayjs;
  onBack: () => void;
  locationId: string;
  vendorId: string;
  locationServiceVendors?: LocationServiceVendor;
}

let VisitDetails: FC<VisitDetailsProps> = ({
  selectedDay,
  locationId,
  vendorId = null,
  locationServiceVendors,
}) => {
  const date = selectedDay.format("YYYY-MM-DD");
  const { data, isFetching: isVisitsFetching, ...reset } = useGetLocationVisitsQuery({
    locationId,
    date,
    vendorId,
  });

  if (isVisitsFetching) {
    return(<div>Loading...</div>)
  } else {
    if (!data?.visits[date]) {
      return (
        <Box padding={2}>
          <Alert severity="warning" sx={{ p: 1, fontSize: "14px" }}>
            There is no data for this date
          </Alert>
        </Box>
      );
    } else {
      return (
        <>{
          map(data.visits[date], (v, index) => {
          return (
            <VisitBox
              startOpen={true}
              visit={v}
              key={index}
              locationServiceVendors={locationServiceVendors}
            />
          );
          })
          }
        </>
      );
     }
  }
};

export default VisitDetails;
