import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  List,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { map } from "lodash";
import { DeliveryTable } from "service/DeliveryInstruction";
import { StockDeliveryRequest } from "service/ticket/types";
import BinDot from "service/BinDot";
import { formatDate, useBinFetch } from "sharedUtils";
import { ActionButtons } from "./AdhocVisitRequestCard";
import ShowMoreDivider from "service/forms/common/ShowMoreDivider";
import { useContext } from "react";
import AppContext from "ticket/AppContext";
import CustomTimelineNotes from "service/forms/common/CustomTimeLineNotes";

interface StockDeliveryRequestCardProps {
  request: StockDeliveryRequest;
  isOpen: boolean;
  toggleOpen: (open: any) => void;
}

const StockDeliveryRequestCard: React.FC<StockDeliveryRequestCardProps> = ({
  request,
  isOpen,
  toggleOpen,
}) => {
  const {
    isConfirmed,
    isCancelled,
    isActive,
    actions,
    notes,
    deliveryUnits,
    requestDeliveryCodes,
  } = request;

  const { selectedContainer } = useContext(AppContext);

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const deliveryRows = map(deliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });
  return (
    <Box
      sx={{
        p: 1,
        pt: 0,
        ...(selectedContainer === request.id && {
          "& .MuiPaper-root": {
            border: "3px solid #6c8c89",
          },
        }),
      }}
      key={request.id}
      data-link-card={request.id}
    >
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          background: "#262B30",
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
          "& *": {
            fontSize: "12px",
            color: "#fff",
          },
        }}
      >
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Stock Delivery Request
              </Typography>
              <Typography color="grey" sx={{ ml: "4px", fontSize: "10px" }}>
                on {formatDate(request?.requestedAt)}
              </Typography>
            </Box>

            <ActionButtons
              actions={actions}
              isActive={isActive}
              request={request}
              linkSelected={selectedContainer === request?.id}
            />
          </Stack>
          {isConfirmed && (
            <Typography
              fontSize={"12px"}
              fontWeight={"bold"}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "#2dcfba",
              }}
            >
              <LocalShippingIcon sx={{ fill: "#2dcfba" }} />
              Confirmed by vendor on {formatDate(request?.date)}
            </Typography>
          )}

          {isCancelled && (
            <Typography
              fontSize={"12px"}
              fontWeight={"bold"}
              sx={{ display: "flex", color: "#F60C0C" }}
            >
              <CloseIcon sx={{ fill: "#F60C0C" }} />
              Cancelled on {formatDate(request?.cancelledOn)}
            </Typography>
          )}

          <Typography variant="h6">
            Requested stock to be delivered on next visit
          </Typography>

          <List sx={{ width: "100%" }}>
            <ShowMoreDivider toggleOpen={toggleOpen} isOpen={isOpen} />

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <DeliveryTable rows={deliveryRows} label={false} />
              <CustomTimelineNotes notes={notes} />
            </Collapse>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default StockDeliveryRequestCard;
