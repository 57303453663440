import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { StakeholderContacts } from "api/tickets";
import {
  prepareCannedResponse,
  useCannedResponses,
} from "hooks/useCannedResponses";
import { useFetch } from "hooks/useFetch";
import { lowerCase, pick } from "lodash";
import React, { createContext, useContext, useState } from "react";
import getType from "service/forms";
import { scrollToBottom } from "service/ticket/utils";
import { useFormServiceActionMutation } from "../../api/services";
import { ServiceActionMessageForm } from "./ServiceActionMessageForm";
import FormSkeleton from "./common/FormSkeleton";
import AppContext from "ticket/AppContext";
import { useTicket } from "ticket/selectors";
import AgentSelect from "service/ticket/channel-footer/form/AgentSelect";

interface NewMessageActionFormProps {
  formUrl: string;
  onClose: () => void;
  stakeholderContacts: {
    organisation: StakeholderContacts;
    agent: StakeholderContacts;
    vendor: StakeholderContacts;
  };
  reply?: any;
  setOpenFormUrl: (formUrl: string) => void;
  selectedService: string;
  handleServiceChange: (event: any) => void;
}

const formTypeMapping = {
  adhoc: ["adhoc"],
  resumption: ["resumption"],
  stock: ["stock"],
  spec: ["spec"],
  frequency: ["frequency"],
  suspension: ["suspension"],
  termination: ["termination"],
};

const formDefaults = {
  request: {
    vendorMessage: true,
    vendorResponseExpected: true,
    // organisationMessage: false,
    // organisationResponseExpected: false,
  },
};

export const MessageFormContext = createContext({
  vendorResponse: {},
  vendorError: false,
  sendMessageVendor: false,
  setSendMessageVendor: (_x) => {},
  stakeholderContacts: {},
  setVendorError: (_x) => {},
  organisationResponse: {},
  organisationError: false,
  sendMessageOrganisation: false,
  setSendMessageOrganisation: (_x) => {},
  setOrganisationError: (_x) => {},
});

const NewMessageActionForm: React.FC<NewMessageActionFormProps> = ({
  formUrl,
  onClose,
  stakeholderContacts,
  reply,
  selectedService,
  handleServiceChange,
}) => {
  const ticket = useTicket();
  const { setFormType, setCardTab, activeServices, servicesToResume } =
    useContext(AppContext);
  const [sendMessageOrganisation, setSendMessageOrganisation] = useState(true);
  const [sendMessageVendor, setSendMessageVendor] = useState(true);

  const [organisationError, setOrganisationError] = useState(false);
  const [vendorError, setVendorError] = useState(false);
  const [assignedAgentId, setAssignedAgentId] = useState(
    ticket?.assignedAgent?.id || "",
  );

  const [formData, setFormData, formLoaded] = useFetch(formUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const [submitForm, submitFormResult] = useFormServiceActionMutation();

  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const { vendorResponse, organisationResponse, responsesLoaded } =
    useCannedResponses(formUrl, formData);

  const isOrganisationMessageEmpty =
    sendMessageOrganisation && !organisationResponse?.message;
  const isVendorMessageEmpty = sendMessageVendor && !vendorResponse?.message;

  const FormType = formData ? getType(formData?.type) : null;

  const updateForm = (newFormArgs) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        form: {
          ...prevData.form,
          ...newFormArgs,
        },
      };
    });
  };

  const submitHandler = () => {
    let hasError = false;

    if (isOrganisationMessageEmpty) {
      setOrganisationError(true);
      hasError = true;
    }

    if (isVendorMessageEmpty) {
      setVendorError(true);
      hasError = true;
    }

    if (hasError) return;

    submitForm({
      url: formUrl,
      body: prepareCannedResponse({
        form: {
          ...formData.form,
          assignedAgentId,
          organisationResponse: pick(organisationResponse, [
            "toContactId",
            "responseExpected",
            sendMessageOrganisation && "message",
          ]),
          vendorResponse: pick(vendorResponse, [
            "toContactId",
            "responseExpected",
            sendMessageVendor && "message",
          ]),
        },
      }),
    })
      .unwrap()
      .then(({ data }) => setFormData(data))
      .then(() => {
        const formTypeLowerCase = lowerCase(formData.type);
        // Find the form type based on the formData.type
        const foundFormType = Object.keys(formTypeMapping).find((key) =>
          formTypeMapping[key].some((type) => formTypeLowerCase.includes(type)),
        );

        if (formTypeLowerCase.includes("report")) {
          setCardTab("report");
        } else {
          // Set the found form type, default to a fallback if not found
          setFormType(foundFormType || "all");
        }
      })
      .then(() => scrollToBottom())
      .then(() => onClose())
      .catch(({ data }) => setFormData(data));
  };

  const showForm = formLoaded && responsesLoaded && FormType && selectedService;
  const resumeService = formData?.type === "RequestResumptionForm";

  // TODO: Add defaults for feedbacks
  // use reportvisit, if use report, collide with feedbacks
  const isRequestOrCancelOrReport = ["request", "cancel", "reportvisit"].some(
    (word) => lowerCase(formData?.type).includes(word),
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          position: "absolute",
          top: 0,
          right: 1,
        }}
      >
        <IconButton size="large" onClick={onClose}>
          <CloseIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      </Box>

      {resumeService && servicesToResume.length > 1 && (
        <FormControl size="small" sx={{ mb: 1, width: "50%" }}>
          <Select
            value={selectedService}
            onChange={handleServiceChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Choose a service
            </MenuItem>
            {servicesToResume.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service?.title} - {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {!resumeService && activeServices.length > 1 && (
        <FormControl size="small" sx={{ mb: 1, width: "50%" }}>
          <Select
            value={selectedService}
            onChange={handleServiceChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Choose a service
            </MenuItem>
            {activeServices.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service?.title} - {service.vendorAccount.nameWithoutClosed}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showForm ? (
        <MessageFormContext.Provider
          value={{
            vendorResponse,
            vendorError,
            sendMessageVendor,
            setSendMessageVendor,
            setVendorError,
            stakeholderContacts,
            organisationResponse,
            organisationError,
            sendMessageOrganisation,
            setSendMessageOrganisation,
            setOrganisationError,
          }}
        >
          <FormType
            {...formData}
            replyVisitFailure={reply}
            onSetForm={updateForm}
          />
          <ServiceActionMessageForm
            formDefaults={isRequestOrCancelOrReport && formDefaults["request"]}
          />

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
            sx={{ width: "100%", pt: 1 }}
          >
            <AgentSelect
              assignedAgentId={assignedAgentId}
              setAssignedAgentId={setAssignedAgentId}
            />
            <Box sx={{ flex: 1 }} />
            <Button
              variant="outlined"
              color="info"
              onClick={() => onClose()}
              sx={{ fontSize: "12px", width: "200px" }}
            >
              Cancel
            </Button>
            <Box>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmittting || organisationError || vendorError}
                onClick={submitHandler}
                color={isSubmitError ? "error" : "success"}
                sx={{ fontSize: "12px", width: "200px" }}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </MessageFormContext.Provider>
      ) : (
        <FormSkeleton />
      )}
    </>
  );
};

export default NewMessageActionForm;
