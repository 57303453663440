import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import VisitFailureChips from "./common/VisitFailureChips";
import PartiallyCollectedTableBins from "./common/PartiallyCollectedTableBins";
import { ModelErrors } from "sharedComponents";
import { isEmpty } from "lodash";
import { formatDate } from "sharedUtils";
import NotesSection from "./common/NotesSection";
import dayjs from "dayjs";

const ClientFeedbackOnFailedVisitReportForm = ({
  form,
  context,
  errors,
  onSetForm,
}) => {
  const { collectionResultSlug, deliveryResultSlug, reportUuid } = form;

  const {
    serviceName,
    deliveryResults,
    collectionResults,
    inUseBinGroups,
    visitPlanName,
    allReports,
  } = context;

  const showPartialCollected =
    !isEmpty(inUseBinGroups) &&
    ["partial_failure"].includes(collectionResultSlug);

  const collectionHandler = (slug) =>
    onSetForm({
      collectionResultSlug: slug,
    });

  const deliveryHandler = (slug) =>
    onSetForm({
      deliveryResultSlug: slug,
    });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Client Feedback
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs
          sx={{
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box>
            <Typography>Failure Date</Typography>
            <FeedbackDateSelect
              allReports={allReports}
              selectedValue={reportUuid}
              onChange={(reportUuid) => {
                onSetForm({ reportUuid });
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& > *": {
              flex: 1,
            },
          }}
        >
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste collected
            </Typography>
            <VisitFailureChips
              options={collectionResults}
              selectedSlug={collectionResultSlug}
              onChange={collectionHandler}
              showReasonFailure={false}
              onChangeSelect={(event) =>
                onSetForm({
                  collectionFailureReasonSlug: event.target.value,
                })
              }
            />
          </div>
          <div>
            <Typography variant="h6" fontWeight={"bold"}>
              Was waste delivered
            </Typography>
            <VisitFailureChips
              options={deliveryResults}
              selectedSlug={deliveryResultSlug}
              onChange={deliveryHandler}
              showReasonFailure={false}
              onChangeSelect={(event) =>
                onSetForm({
                  deliveryFailureReasonSlug: event.target.value,
                })
              }
            />
          </div>
        </Grid>

        {showPartialCollected && (
          <PartiallyCollectedTableBins
            inUseBinGroups={inUseBinGroups}
            onSetForm={onSetForm}
          />
        )}
      </Grid>

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Feedback By"
        contactPlaceholder="Who reported the feedback?"
        dateLabel="Feedback on"
        dateValue={form?.reportedOn}
        onChange={(newValue) =>
          onSetForm({
            reportedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />

      <ModelErrors errors={errors} />
    </div>
  );
};

export const FeedbackDateSelect = ({ allReports, onChange, selectedValue }) => {
  const handleChange = (event) => onChange(event.target.value);

  return (
    <FormControl>
      <Select
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        disabled={allReports.length === 1}
        size="small"
      >
        <MenuItem value="" disabled>
          Choose a date
        </MenuItem>
        {allReports.map((report) => (
          <MenuItem
            key={report.uuid}
            value={report.uuid}
            sx={{ fontSize: "12px" }}
          >
            {formatDate(report.failureDate)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClientFeedbackOnFailedVisitReportForm;
