import ServiceBox from "./ServiceBox";
import { Card, CardContent, Divider } from "@mui/material";
import VendorHeader from "./VendorHeader";
import RequestsCollapsible from "./service-box/RequestsCollapsible";

const LocationServicesVendorBox = ({
  vendor,
  services,
  onSelectAction,
  calendar,
}) => {
  return (
    <>
      <Card
        sx={{
          "& .MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <CardContent
          sx={{
            m: 2,
            my: 0,
            p: 0,
          }}
        >
          <VendorHeader vendor={vendor} calendar={calendar} />
          {services?.map((service) => (
            <>
              <Divider sx={{ m: 0, mx: -2 }} />
              {/* <Box */}
              {/*   sx={{ background: "#00193D", mx: -2, minHeight: "25px", py: 2 }} */}
              {/* > */}
              {/*   <Typography */}
              {/*     sx={{ */}
              {/*       fontSize: "14px", */}
              {/*       fontWeight: "bold", */}
              {/*       color: "#fff", */}
              {/*       mx: 2, */}
              {/*     }} */}
              {/*   > */}
              {/*     Performance Summary */}
              {/*   </Typography> */}
              {/* </Box> */}
              {/* <RemediationCollapsible service={service} /> */}
              <ServiceBox
                key={service.id}
                onSelectAction={onSelectAction}
                service={service}
              />
              <RequestsCollapsible service={service} />
            </>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default LocationServicesVendorBox;
