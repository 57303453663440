import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { nth, startCase, uniq } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomTable from "service/forms/common/CustomTable";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";

dayjs.extend(relativeTime);

const RequestsCollapsible = ({ service }) => {
  const [open, setOpen] = useState(true);
  const { currentRequests: requests } = service;
  const [hasOverflow, setHasOverflow] = useState(false);
  const typesContainerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { setHighlightedId } = useContext(AppContext);

  const headers = ["Type", "Ticket ID", "Sent", ""];
  const requestsList = uniq(requests?.map((r) => r.type));

  useEffect(() => {
    const checkOverflow = () => {
      if (typesContainerRef.current) {
        setHasOverflow(
          typesContainerRef.current.scrollWidth >
            typesContainerRef.current.clientWidth,
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [requestsList]);

  useEffect(() => {
    if (requests.length === 0) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has("highlight")) {
        searchParams.delete("highlight");
        history.replace({
          search: searchParams.toString(),
        });
        setHighlightedId(null);
      }
    }
  }, [requests.length, history, location.search, setHighlightedId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#1C365B",
          color: "#fff",
          mx: -2,
          minHeight: "43px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "#fff",
              mx: 2,
              whiteSpace: "nowrap",
            }}
          >
            {requests?.length > 0 ? requests.length : "No"} Outstanding Requests
          </Typography>
          <Box
            ref={typesContainerRef}
            sx={{
              display: "flex",
              overflow: "hidden",
              maxWidth: "calc(100% - 200px)",
            }}
          >
            {requestsList?.map((r: string) => (
              <Typography
                sx={{
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  marginRight: "8px",
                  color: "#4FC1E9",
                }}
              >
                {startCase(r)}
              </Typography>
            ))}
          </Box>
          {hasOverflow && (
            <Tooltip
              arrow
              placement="top"
              title={requestsList?.map(startCase).join(", ")}
            >
              <Typography sx={{ fontSize: "12px", marginLeft: "4px" }}>
                ...
              </Typography>
            </Tooltip>
          )}
        </Box>
        {requests.length > 0 && (
          <IconButton
            disableRipple
            onClick={() => setOpen(!open)}
            sx={{
              position: "relative",
              right: "16px",
              py: "4px",
              "& .MuiSvgIcon-root": { fontSize: "35px", color: "#fff" },
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </Box>

      {requests?.length > 0 && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{
            margin: "-8px -16px",
            p: 1,
            background: "#1C365B",
          }}
        >
          <CustomTable
            headers={headers}
            rows={<RequestRows requests={requests} />}
          />
        </Collapse>
      )}
    </>
  );
};

export default RequestsCollapsible;

const RequestRows = ({ requests }) => {
  const history = useHistory();
  const location = useLocation();
  const { highlightedId, setHighlightedId } = useContext(AppContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const highlightId = params.get("highlight");
    if (highlightId) {
      setHighlightedId(highlightId);
      setTimeout(() => {
        const element = document.getElementById(`reply-${highlightId}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, [location, setHighlightedId]);

  const handleIconClick = (request) => {
    // Get id from url
    const urlArray = window.location.pathname.split("/");
    const currentTicketId = nth(urlArray, -2);

    if (request.linkedTicketIds[0] === Number(currentTicketId)) {
      const searchParams = new URLSearchParams(location.search);

      if (highlightedId === request.id) {
        // If it's already highlighted, remove the highlight
        searchParams.delete("highlight");
        setHighlightedId(null);
      } else {
        // If it's not highlighted, set the highlight
        searchParams.set("highlight", request.id);
        setHighlightedId(request.id);
      }

      // Update the URL and push to history
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // we are not on the same ticket
      // Implement logic for opening in a new tab if needed
      const newTabUrl = `/admin/tickets/${request.linkedTicketIds[0]}/app?highlight=${request.id}`;
      window.open(newTabUrl, "_blank");
    }
  };

  const handleLinkClick = (e, isInCurrentPath) => {
    if (isInCurrentPath) {
      e.preventDefault();
    }
  };

  return requests.map((request, index) => {
    const isSelected = highlightedId === request.id;
    const currentPath = `/admin/tickets/${request.linkedTicketIds[0]}/app`;
    const isInCurrentPath = window.location.pathname === currentPath;
    const isLastRow = index === requests.length - 1;

    return (
      <TableRow
        key={request.id}
        sx={{
          color: "#fff",
          ...(isLastRow && {
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          }),
        }}
      >
        <TableCell>
          <Typography noWrap sx={{ fontSize: "11px" }}>
            {startCase(request.type)}
          </Typography>
        </TableCell>

        <TableCell>
          <Link
            target="_blank"
            href={`/admin/tickets/${request.linkedTicketIds}/app`}
            onClick={(e) => handleLinkClick(e, isInCurrentPath)}
            underline="none"
            sx={{
              color: isInCurrentPath ? "#fff" : "#4FC1E9 !important",
              "&:hover": {
                ...(isInCurrentPath ? { color: "#fff" } : {}),
              },
            }}
          >
            {isInCurrentPath ? "This ticket" : `#${request.linkedTicketIds}`}
          </Link>
        </TableCell>
        <TableCell>
          <Tooltip title={formatDate(request.requestedAt)} arrow>
            <span>
              <TimeChip date={request.requestedAt} />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleIconClick(request)}>
            <LinkIcon
              sx={{
                color: isSelected ? "#4FC1E9" : "#fff",
              }}
            />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
};

const TimeChip = ({ date }) => {
  const now = dayjs();
  const requestDate = dayjs(date);
  const diffInDays = now.diff(requestDate, "day");
  const isOverTwoDays = diffInDays > 2;

  if (isOverTwoDays) {
    return (
      <Chip
        label={dayjs(date).fromNow()}
        color="error"
        sx={{ fontSize: "11px" }}
      />
    );
  }

  return dayjs(date).fromNow();
};
