import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import BinDot from "service/BinDot";
// import { ModelErrors } from "/sharedComponents";
import { useBinFetch } from "sharedUtils";
import CustomTable from "./common/CustomTable";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";
import NotesSection from "./common/NotesSection";
import { ModelErrors } from "sharedComponents";

var ConfirmStockDeliveryForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  var {
    visitPlanName,
    serviceName,
    requestDeliveryCodes,
    requestDeliveryUnits,
    nextVisits,
  } = context;

  const { binTypes, binGroups } = useBinFetch(requestDeliveryCodes);

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const rows = _.map(requestDeliveryUnits, ({ serviceCode, quantity }) => {
    const binType = binTypes[serviceCode];
    const binGroup = binGroups[binType?.binGroupId];

    return (
      <TableRow key={serviceCode}>
        <TableCell scope="row">
          <BinDot
            binGroup={binGroup}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>{binType?.name}</TableCell>
        <TableCell>{serviceCode}</TableCell>
        <TableCell>{quantity}</TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm the Stock Delivery Request for the Next Visit
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <div>
          <label>Confirmed for</label>
          <ServiceCalendarDatePicker
            open={open}
            setOpen={setOpen}
            visitDays={visitDays}
            onChange={(newValue) =>
              onSetForm({ date: newValue.format("DD/MM/YYYY") })
            }
          />
        </div>
        <CustomTable
          rows={rows}
          headers={["Type", "Name", "Code", "Confirm Delivery of # Units"]}
        />
      </Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Confirmed by"
        contactPlaceholder="Who confimed the request?"
        dateLabel="Received on"
        dateValue={form?.receivedOn}
        onChange={(newValue) =>
          onSetForm({
            receivedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default ConfirmStockDeliveryForm;
