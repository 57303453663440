import ConfirmNextRoutineVisitForm from "./ConfirmNextRoutineVisitForm";
import ConfirmFinalCollectionForm from "./ConfirmFinalCollectionForm";
import RequestVisitFrequencyChangeForm from "./RequestVisitFrequencyChangeForm";
import AcceptVisitFrequencyChangeForm from "./AcceptVisitFrequencyChangeForm";
import ConfirmVisitFrequencyChangeForm from "./ConfirmVisitFrequencyChangeForm";
import CancelVisitFrequencyChangeForm from "./CancelVisitFrequencyChangeForm";
import RequestTerminationForm from "./RequestTerminationForm";
import CancelTerminationForm from "./CancelTerminationForm";
import ConfirmTerminationForm from "./ConfirmTerminationForm"
import RequestResumptionForm from "./RequestResumptionForm";
import RequestSpecChangeForm from "./RequestSpecChangeForm";
import CancelSpecChangeForm from "./CancelSpecChangeForm";
import RequestSuspensionForm from "./RequestSuspensionForm";
import CancelSuspensionForm from "./CancelSuspensionForm";
import ConfirmSuspensionForm from "./ConfirmSuspensionForm";
import RequestAdhocVisitForm from "./RequestAdhocVisitForm";
import RequestStockDeliveryForm from "./RequestStockDeliveryForm";
import CancelStockDeliveryForm from "./CancelStockDeliveryForm";
import ConfirmStockDeliveryForm from "./ConfirmStockDeliveryForm";
import CancelAdhocVisitForm from "./CancelAdhocVisitForm";
import ConfirmAdhocVisitForm from "./ConfirmAdhocVisitForm";
import ReportVisitFailureForm from "./ReportVisitFailureForm";
import ClientFeedbackOnFailedVisitReportForm from "./ClientFeedbackOnFailedVisitReportForm";
import VendorFeedbackOnFailedVisitReportForm from "./VendorFeedbackOnFailedVisitReportForm";
import ConfirmVisitFailureReattemptDateForm from "./ConfirmVisitFailureReattemptDateForm";

const types = {
  ConfirmNextRoutineVisitForm,
  ConfirmFinalCollectionForm,
  RequestVisitFrequencyChangeForm,
  AcceptVisitFrequencyChangeForm,
  ConfirmVisitFrequencyChangeForm,
  CancelVisitFrequencyChangeForm,
  RequestTerminationForm,
  CancelTerminationForm,
  ConfirmTerminationForm,
  RequestResumptionForm,
  RequestSuspensionForm,
  CancelSuspensionForm,
  ConfirmSuspensionForm,
  RequestAdhocVisitForm,
  RequestSpecChangeForm,
  CancelSpecChangeForm,
  RequestStockDeliveryForm,
  CancelStockDeliveryForm,
  ConfirmStockDeliveryForm,
  CancelAdhocVisitForm,
  ConfirmAdhocVisitForm,
  ReportVisitFailureForm,
  ClientFeedbackOnFailedVisitReportForm,
  VendorFeedbackOnFailedVisitReportForm,
  ConfirmVisitFailureReattemptDateForm,
};

export default (type) => {
  const component = types[type];

  if (component) {
    return component;
  } else {
    throw (
      'missing form component for "' +
      type +
      '", perhaps you forgot to add it to forms/index.js?'
    );
  }
};
