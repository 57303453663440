import React from "react";
import { Typography } from "@mui/material";
import { ServiceTerminationRequest } from "service/ticket/types";
import { formatDate } from "sharedUtils";

interface ServiceTerminationRequestCardDetailsProps {
  request: ServiceTerminationRequest;
}

const ServiceTerminationRequestCardDetails: React.FC<
  ServiceTerminationRequestCardDetailsProps
> = ({ request }) => {
  const finalCollectionRequired =
    request.finalCollectionRequired && request.finalCollectionRequiredBefore;

  return (
    <>
      <Typography>
        Vendor has been requested to terminate the service from {formatDate(request?.requestedAt)}
      </Typography>
      {finalCollectionRequired && (
        <Typography>
          Final Collection Required Before:{" "}
          {formatDate(request?.finalCollectionRequiredBefore)}
        </Typography>
      )}
    </>
  );
};

export default ServiceTerminationRequestCardDetails;
