import { Box, Divider, Typography } from "@mui/material";
import { startCase } from "lodash";
import { useContext } from "react";
import AppContext from "ticket/AppContext";
import { formatDate } from "sharedUtils";
import { STATUS_COLORS } from "./forms/common/VisitBoxButtons";
import { useDispatch } from "react-redux";
import { setVisitDate } from "api/visitDateSlice";

const VendorHeader = ({ vendor, calendar }) => {
  const { nextVisit, lastVisit } = calendar;
  const { onClickVisitDate, setServiceBoxTab, setVisitTab } =
    useContext(AppContext);

  const dispatch = useDispatch();
  const onClickHandler = (date) => {
    onClickVisitDate(date);
    dispatch(setVisitDate(date));
    setVisitTab(formatDate(date));
    setServiceBoxTab(1);
  };

  const backgroundColor =
    {
      success: "#E6F8F1",
      failure: "#FFECEC",
      conflicting: "#FFF0E2",
      unknown: "#D9E2FF",
      planned: "#f5bd43",
    }[lastVisit?.status] || "#D9E2FF";

  const lastVisitColor = STATUS_COLORS[lastVisit?.status];

  const nextVisitColor = STATUS_COLORS[nextVisit?.status];

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        "& .MuiTypography-root": {
          fontSize: "12px",
          fontWeight: "bold",
        },
        "& span": {
          color: "#3BAFDA",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          flexDirection: "column",
          flex: 1,
          m: 1,
          mr: 2,
        }}
      >
        <Box
          component="img"
          src={vendor.logo.header.url}
          alt={vendor.name}
          onClick={() => window.open(`/admin/vendors/${vendor?.id}`, "_blank")}
          sx={{
            cursor: "pointer",
            height: "30px",
            objectFit: "contain",
          }}
        />
        <Typography noWrap sx={{ color: "#828B98", mt: 0.5 }}>
          T: {vendor?.telephone}
        </Typography>
      </Box>
      <Box
        sx={{
          minWidth: "320px",
          display: "flex",
          alignItems: "center",
          // flex: 1,
          background: backgroundColor,
          m: 0,
          mr: -2,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <Typography
            noWrap
            onClick={() => onClickHandler(lastVisit?.date)}
            sx={{
              cursor: "pointer",
            }}
          >
            Last Visit
            <span> {lastVisit?.date && formatDate(lastVisit?.date)}</span>
          </Typography>
          <Typography
            noWrap
            onClick={() => onClickHandler(nextVisit?.date)}
            sx={{
              cursor: "pointer",
            }}
          >
            Next Visit
            <span> {nextVisit?.date && formatDate(nextVisit?.date)}</span>
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ my: 3 }}
        />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            mx: 2,
          }}
        >
          <Box>
            <Typography sx={{ color: lastVisitColor }}>
              {startCase(lastVisit?.status)}&nbsp;
            </Typography>
            <Typography sx={{ color: nextVisitColor }}>
              {startCase(nextVisit?.status)}&nbsp;
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorHeader;
