import { createContext } from "react";

const AppContext = createContext({
  onSelectAction: (_x, {}) => {},
  onClickVisitDate: (_x) => {},
  setServiceBoxTab: (_x) => {},
  setVisitTab: (_x) => {},
  selectedContainer: undefined,
  setSelectedContainer: (_x) => {},
  formType: "all",
  setFormType: (_x) => {},
  cardTab: "request",
  setCardTab: (_x) => {},
  activeServices: [],
  servicesToResume: [],
  showTaggingForm: false,
  setShowTaggingForm: (_x) => {},
  highlightedId: "",
  setHighlightedId: (_x) => {},
});

export default AppContext;
