import {
  Checkbox,
  ToggleButtonGroup,
  styled,
  ToggleButton,
  Grid,
  Typography,
} from "@mui/material";
import { forEach } from "lodash";
import BinDot from "service/BinDot";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

const PartiallyCollectedTableBins = ({ inUseBinGroups, onSetForm }) => {
  const [alignment, setAlignment] = useState("uncollected");
  const [checkedStates, setCheckedStates] = useState({});

  const handleAlignment = (_event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleCheckboxChange = (binGroup, e) => {
    const isChecked = e.target.checked;

    setCheckedStates((prevCheckedStates) => {
      const updatedCheckedStates = {
        ...prevCheckedStates,
        [binGroup.id]: {
          isChecked,
          alignment: isChecked ? alignment : "uncollected",
        },
      };
      const binGroupCollectionStatus = {};
      forEach(updatedCheckedStates, (value, key) => {
        binGroupCollectionStatus[key] = value.alignment !== "uncollected";
      });

      onSetForm({
        binGroupCollectionStatus,
      });

      return updatedCheckedStates;
    });
  };

  const collectionRows = inUseBinGroups.map((binGroup) => {
    const { isChecked, alignment } = checkedStates[binGroup.id] || {};

    const icon =
      alignment === "uncollected" && isChecked ? (
        <CloseIcon />
      ) : alignment === "collected" && isChecked ? (
        <CheckIcon />
      ) : undefined;

    return (
      <Grid
        item
        xs
        key={binGroup.id}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <BinDot binGroup={binGroup} tooltipName={true} tooltipPlace="top" />
        <Typography noWrap fontSize="12px">
          {binGroup.name}
        </Typography>
        <Checkbox
          checkedIcon={icon}
          checked={isChecked}
          color={alignment === "collected" ? "success" : "error"}
          onChange={(e) => handleCheckboxChange(binGroup, e)}
        />
      </Grid>
    );
  });

  return (
    <Grid item xs={12}>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        fullWidth
      >
        <StyledToggleButton
          value="uncollected"
          selected={alignment === "uncollected"}
        >
          What didn't get collected?
        </StyledToggleButton>
        <StyledToggleButton
          value="collected"
          selected={alignment === "collected"}
        >
          What did get collected?
        </StyledToggleButton>
      </ToggleButtonGroup>
      <Grid container spacing={1}>
        {collectionRows}
      </Grid>
    </Grid>
  );
};

export default PartiallyCollectedTableBins;

const StyledToggleButton = styled(ToggleButton)(({ theme, value }) => ({
  fontSize: "12px",
  flex: 1,
  "&.Mui-selected": {
    backgroundColor:
      value === "uncollected"
        ? theme.palette.error.main
        : theme.palette.success.main,
    color: "white",
    "&:hover": {
      backgroundColor:
        value === "uncollected"
          ? theme.palette.error.dark
          : theme.palette.success.dark,
    },
  },
  backgroundColor: "white",
  color: "black",
  "&:hover": {
    backgroundColor: "#eee",
  },
}));
