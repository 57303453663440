import VisitDetails from "service/VisitDetails";
import { map } from "lodash";
import LocationServicesVendorBox from "service/LocationServicesVendorBox";
import { useGetLocationServiceVendorsQuery } from "api/services";
import {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "service/report-request-box/MainTabs";
import { getVendorId } from "ticket/selectors";
import AppContext from "ticket/AppContext";
import { useState } from "react";
import { Grid } from "@mui/material";

let SideBar = ({
  selectedDay,
  locationId,
  serviceBoxTab,
  setServiceBoxTab,
  visitTab,
  setVisitTab,
}) => {
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  const [, onClickVisitDate] = useState();

  const vendorId = getVendorId();

  const handleServiceBoxTab = (_event, newValue) => {
    setServiceBoxTab(newValue);
    setVisitTab(undefined);
  };

  const onSelectAction = () => undefined;

  return (
    <AppContext.Provider
      value={{
        onSelectAction,
        onClickVisitDate,
        setServiceBoxTab,
        setVisitTab,
      }}
    >
      <StyledTabs value={serviceBoxTab} onChange={handleServiceBoxTab}>
        <StyledTab label="Services" />
        {visitTab && <StyledTab label={visitTab} />}
      </StyledTabs>
      <TabPanel value={serviceBoxTab} index={0}>
        <Grid container spacing={1}>
          {map(locationServiceVendors, (locationServiceVendor) => {
            return (
              <Grid item sx={{ width: "100%" }}>
                <LocationServicesVendorBox
                  onSelectAction={onSelectAction}
                  key={locationServiceVendor.vendor.id}
                  {...locationServiceVendor}
                />
              </Grid>
            );
          })}
        </Grid>
      </TabPanel>
      <TabPanel value={serviceBoxTab} index={1}>
        <VisitDetails
          selectedDay={selectedDay}
          locationId={locationId}
          vendorId={vendorId}
          onBack={() => undefined}
          locationServiceVendors={locationServiceVendors}
        />
      </TabPanel>
    </AppContext.Provider>
  );
};

export default SideBar;
