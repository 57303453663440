import React from "react";
import PropTypes from "prop-types";
import {
  ShowDate
} from 'sharedComponents'

class ServiceLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = { minimized: true }
    this.handleToggleMinimized = this.handleToggleMinimized.bind(this)
  }

  handleToggleMinimized() {
    this.setState({minimized: !this.state.minimized})
  }

  render() {
    const { service } = this.props
    const { log }  = service
    const { minimized } = this.state

    const toggleMinimized = <div className='pull-right'>
      <i className={`fa fa-${ minimized ? 'chevron-down' : 'chevron-up' } text-muted`} style={{ cursor: 'pointer', marginRight: 10}}></i>
    </div>

    const heading = <div className="mb10 mt10 text-muted">
      <span className="badge badge-primary">{log.length}</span> &nbsp; Log
    </div>

    const childrenDivClass = minimized ? 'hidden' : ''

    return log.length == 0 ? null : (
      <div style={{ marginBottom: minimized ? 20 : 40 }}>
        <div style={{cursor: 'pointer'}} onClick={this.handleToggleMinimized}>
          {toggleMinimized}
          {heading}
        </div>
        <div className="">
          <div className={childrenDivClass}>
            {log.map(({title, data, timestamp, id}) => {
              return(
                <div className='well' key={id}>
                  <strong><ShowDate date={timestamp}/> - {title}</strong>
                  <pre className='my-5'>{JSON.stringify(data, null, 2)}</pre>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}


ServiceLog.propTypes = {
  service: PropTypes.shape({
    log: PropTypes.arrayOf(PropTypes.object)
  }),
}


export default ServiceLog

