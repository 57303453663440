import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { prepareCannedResponse } from "hooks/useCannedResponses";
import { useFetch } from "hooks/useFetch";
import { useContext } from "react";
import getType from "service/forms";
import { useFormServiceActionMutation } from "../../api/services";
import AppContext from "ticket/AppContext";

export const TaggingForm = ({ formUrl, onClose, reply }) => {
  const [formData, setFormData, formLoaded] = useFetch(formUrl, {
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const [submitForm, submitFormResult] = useFormServiceActionMutation();

  const { isError: isSubmitError, isLoading: isSubmittting } = submitFormResult;

  const FormType = formData ? getType(formData?.type) : null;
  const showForm = formLoaded && FormType;

  const updateForm = (newFormArgs) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        form: {
          ...prevData.form,
          ...newFormArgs,
        },
      };
    });
  };

  const submitHandler = () => {
    let hasError = false;

    if (hasError) return;

    submitForm({
      url: formUrl,
      body: prepareCannedResponse({
        form: {
          ...formData.form,
        },
      }),
    })
      .unwrap()
      .then(({ data }) => setFormData(data))
      .then(() => onClose())
      .catch(({ data }) => setFormData(data));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          position: "absolute",
          top: 0,
          right: 1,
        }}
      >
        <IconButton size="large" onClick={onClose}>
          <CloseIcon sx={{ fontSize: "30px" }} />
        </IconButton>
      </Box>

      {showForm && (
        <FormType
          {...formData}
          replyVisitFailure={reply}
          onSetForm={updateForm}
        />
      )}

      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        sx={{ width: "100%", pt: 1 }}
      >
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          color="info"
          onClick={() => onClose()}
          sx={{ fontSize: "12px", width: "200px" }}
        >
          Cancel
        </Button>
        <Box>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmittting}
            onClick={submitHandler}
            color={isSubmitError ? "error" : "success"}
            sx={{ fontSize: "12px", width: "200px" }}
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </>
  );
};
