import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const ORGANISATION_COLOR = "#37BC9B";
export const VENDOR_COLOR = "#E95740";
export const AGENT_COLOR = "#F6BB42";

export const channelConfigs = {
  organisation: {
    color: ORGANISATION_COLOR,
    label: "Organisation",
  },
  vendor: {
    color: VENDOR_COLOR,
    label: "Vendor",
  },
};

const channelHeaderContainer: SxProps<Theme> = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "20px",
  gap: 2,
  position: "sticky",
  zIndex: 1,
  color: "white",
};

const channelTypographyStyles: SxProps<Theme> = {
  fontSize: "12px",
  color: "#fff",
};

const boxStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
};

export const styles = {
  channelHeaderContainer,
  channelTypographyStyles,
  boxStyles,
};
