import PropTypes from "prop-types";
import "./styles.scss";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import {
  Box,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  StyledTableBody,
  StyledTableContainer,
  StyledTableRow,
} from "./common/CustomTable";
import _ from "lodash";
import { useBinFetch } from "sharedUtils";
import BinDot from "service/BinDot";
import NotesSection from "./common/NotesSection";

const CancelSpecChangeForm = ({ form, context, errors, onSetForm }) => {
  const {
    serviceName,
    requestSpecifications,
    requestSpecificationsCodes,
  } = context;

  const { binTypes, binGroups } = useBinFetch(requestSpecificationsCodes);

  let rows = _.map(requestSpecificationsCodes, (code) => {
    let { quantity, visitActionId } = requestSpecifications[code] || {};

    const binType = binTypes[code] || {};
    const bg = binGroups[binType.binGroupId];

    return (
      <TableRow key={code}>
        <TableCell scope="row">
          <BinDot
            binGroup={bg}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
          {binType.name}
        </TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{_.startCase(visitActionId)}</TableCell>
      </TableRow>
    );
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel Bin Spec Change
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>

      <Box sx={{ mt: 1 }}>
        <li>
          <strong>
            Vendor was requested to change the bin spec with the following
            {/* {status === "confirmation"
              ? "Vendor has confirmed to change the bin spec the following"
              : "Vendor was requested to change the bin spec with the following"
            } */}
          </strong>
        </li>
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <TableCell>Type</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Cancel Quantity</TableCell>
                <TableCell>Visit Action</TableCell>
              </StyledTableRow>
            </TableHead>
            <StyledTableBody>{rows}</StyledTableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledOn}
        onChange={(newValue) =>
          onSetForm({
            cancelledOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

CancelSpecChangeForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    visitPlanName: PropTypes.string,
    binGroups: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        legendFillColor: PropTypes.string.isRequired,
        legendBorderColor: PropTypes.string.isRequired,
      })
    ).isRequired,
    binTypes: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        groupName: PropTypes.string.isRequired,
        binGroupId: PropTypes.number.isRequired,
      })
    ).isRequired,
    requestSpecifications: PropTypes.objectOf(
      PropTypes.shape({
        quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        serviceCode: PropTypes.string.isRequired,
        visitActionId: PropTypes.string
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default CancelSpecChangeForm;
