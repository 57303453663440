import { Box, Stack, Typography } from "@mui/material";
import { ModelErrors } from "sharedComponents";
import "./styles.scss";
import NotesSection from "./common/NotesSection";

const CancelVisitFrequencyChangeForm = ({ form, context, errors, onSetForm }) => {
  const { serviceName, requestedVisitPlanName } = context;

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {" "}
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel Frequency Change Request
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 1 }}>
        <li><strong>Vendor was requested to change visit frequency to {requestedVisitPlanName}</strong></li>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactLabel="Cancelled By"
          contactPlaceholder="Who requested the cancellation?"
          dateLabel="Cancelled on"
          dateValue={form?.cancelledOn}
          onChange={(newValue) =>
            onSetForm({
              cancelledOn: newValue.format("DD/MM/YYYY"),
            })
          }
        />
        <ModelErrors errors={errors} />
      </Box>
    </div>
  );
};

export default CancelVisitFrequencyChangeForm;
