import { Grid } from "@mui/material";
import OrganisationMessageSection from "./common/OrganisationMessageSection";
import VendorMessageSection from "./common/VendorMessageSection";

export const ServiceActionMessageForm = ({ formDefaults }) => {
  return (
    <Grid container spacing={2}>
      <OrganisationMessageSection formDefaults={formDefaults} />

      <VendorMessageSection formDefaults={formDefaults} />
    </Grid>
  );
};
