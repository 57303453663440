import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import { DaysOfWeekSelect, ModelErrors } from "sharedComponents";
import { Alert, Grid, Stack, Typography, Box } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import _ from "lodash";
import NotesSection from "./common/NotesSection";
import AssumedCancelledVisits from "./common/AssumedCancelledVisits";
import Debugger from "./Debugger";

const getPotentialCancellationDates = (nextVisits, date) => {
  return date
    ? _(nextVisits)
        .reject({ status: "cancelled" })
        .map("date")
        .filter((d) => dayjs(d) < dayjs(date))
        .value()
    : [];
};

const ConfirmVisitFrequencyChangeForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  var { date, visitDayIds } = form;
  var {
    nextVisits,
    serviceName,
    expectedVisitPlanName,
    expectedVisitsPerWeek,
  } = context;

  const potentialCancellationDates = useMemo(
    () => getPotentialCancellationDates(nextVisits, date),
    [nextVisits, date]
  );

  const [selectedDates, setSelectedDates] = useState(
    potentialCancellationDates
  );

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  const renderStandardForm = () => (
    <Grid container spacing={2}>
      <Grid item container xs={4} spacing={2}>
        <Grid item xs>
          <Typography variant="h6" fontWeight="bold" noWrap>
            Next {expectedVisitPlanName} Visit Date
          </Typography>
          <ServiceCalendarDatePicker
            open={open}
            visitDays={visitDays}
            setOpen={setOpen}
            onChange={(newValue) =>
              onSetForm({
                date: newValue.format("YYYY-MM-DD"),
                visitDayIds: [dayjs(newValue).day()],
                cancelledVisitDates: getPotentialCancellationDates(
                  nextVisits,
                  newValue
                ),
              })
            }
          />
        </Grid>

        {date && expectedVisitsPerWeek > 1 && (
          <Grid item xs>
            <Typography variant="h6" fontWeight="bold">
              Select {expectedVisitsPerWeek} days of the week
            </Typography>
            <DaysOfWeekSelect
              blankText="<select days of week>"
              requiredDayId={dayjs(date).day()}
              onSelectDayIds={(visitDayIds) =>
                onSetForm({ visitDayIds: visitDayIds })
              }
              selectedDayIds={visitDayIds}
              timesPerWeek={expectedVisitsPerWeek}
            />
          </Grid>
        )}
      </Grid>
      {potentialCancellationDates?.length > 0 && (
        <>
          <Grid item xs={4}>
            <Alert severity="error" sx={{ fontSize: "12px" }}>
              The date selected as next routine visit is after previously
              planned routine visits and are assumed to be cancelled. Please
              review the date
            </Alert>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" fontWeight="bold">
              {selectedDates.length} Visit
              {selectedDates.length > 1 ? "s" : ""} Assumed Cancelled
            </Typography>
            <AssumedCancelledVisits
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              potentialCancellationDates={potentialCancellationDates}
              onSetForm={onSetForm}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  const renderOnDemandForm = () => (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="h6" fontWeight="bold" noWrap>
          On Demand Visit Date
        </Typography>
        <ServiceCalendarDatePicker
          open={open}
          visitDays={visitDays}
          setOpen={setOpen}
          onChange={(newValue) =>
            onSetForm({
              date: newValue.format("YYYY-MM-DD"),
              visitDayIds: [dayjs(newValue).day()],
              cancelledVisitDates: getPotentialCancellationDates(
                nextVisits,
                newValue
              ),
            })
          }
        />
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        sx={{ "& .MuiTypography-root": { fontWeight: "bold" } }}
      >
        <Typography variant="h5">
          Confirm Frequency Change
        </Typography>
        <Typography color="grey" variant="h6">
          / {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 1 }}>
        <li><strong>Vendor was requested to change visit frequency to {expectedVisitPlanName}</strong></li>
      </Box>

      {expectedVisitPlanName === "On Demand" ? renderOnDemandForm() : renderStandardForm()}

      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactPlaceholder="Who confirmed the date?"
        contactLabel="Confirmed By"
        dateLabel="Confirmed on"
        dateValue={form?.confirmedOn}
        onChange={(newValue) =>
          onSetForm({
            confirmedOn: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <Debugger {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

ConfirmVisitFrequencyChangeForm.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    expectedVisitsPerWeek: PropTypes.number.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    sourceContacts: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmVisitFrequencyChangeForm;
