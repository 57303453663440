import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import _ from "lodash";
import { useState } from "react";
import BinDot from "service/BinDot";
import { useBinFetch } from "sharedUtils";
import { useDebounceValue } from "usehooks-ts";
import BillingHistoryChart from "./service-box/BillingHistoryChart";

export const Specification = ({
  specifications = {},
  billingHistory = [],
  billingHistoryUnitAverages = {},
  ...rest
}) => {
  const billingHistoryLength = billingHistory.length;
  const cloneBillingHistory = _.cloneDeep(billingHistory);

  const [pickStartingFrom, setPickStartingFrom] = useDebounceValue(0, 100);
  const [hiddenServiceCodes, setHiddenServiceCodes] = useState({});

  const slicedBillingHistory = billingHistory.slice(pickStartingFrom);

  const allCodes = _.uniq([
    ..._.map(specifications, "serviceCode"),
    ..._.map(billingHistoryUnitAverages, "code"),
  ]);

  const { binTypes, binGroups } = useBinFetch(allCodes);

  const accumulator = {};

  slicedBillingHistory.forEach((entry) => {
    entry.items.forEach((item) => {
      if (!accumulator[item.serviceCode]) {
        accumulator[item.serviceCode] = { totalQuantity: 0 };
      }
      // Increment total quantity for the serviceCode
      accumulator[item.serviceCode].totalQuantity += item.quantity;
    });
  });

  // Calculate the average quantity for each serviceCode based on the total count of dates
  const billingHistoryUnitAveragesCalc = {};
  Object.keys(accumulator).forEach((code) => {
    billingHistoryUnitAveragesCalc[code] = {
      code,
      quantity: _.round(
        accumulator[code].totalQuantity / billingHistoryLength,
        2
      ),
    };
  });

  let rows = _.map(allCodes, (code) => {
    let { quantity, visitActionId } = specifications[code] || {};

    let { quantity: averageQuantityCalc } =
      billingHistoryUnitAveragesCalc[code] || {};

    let { quantity: averageQuantity } = billingHistoryUnitAverages[code] || {};

    const binType = binTypes[code] || {};
    const bg = binGroups[binType.binGroupId];

    return (
      <TableRow
        key={code}
        sx={{
          color: !hiddenServiceCodes[code] ? "inherit" : "#828B98",
          //color: quantity ? "inherit" : "#828B98",
        }}
      >
        <TableCell
          onClick={() =>
            setHiddenServiceCodes({
              ...hiddenServiceCodes,
              [code]: !hiddenServiceCodes[code],
            })
          }
          sx={{
            cursor: "pointer;",
          }}
        >
          <BinDot
            binGroup={bg}
            tooltipName={true}
            tooltipPlace="top"
            showImg
            {...binType}
          />
        </TableCell>
        <TableCell>
          {averageQuantityCalc || 0} / {averageQuantity || 0}
        </TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{binType.name}</TableCell>
        <TableCell>{visitActionId}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "11px",
            fontWeight: "inherit",
            color: "inherit",
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                fontWeight: "bold",
                color: "#828B98",
                border: "1px solid transparent",
              }}
            >
              <TableCell>Type</TableCell>
              <TableCell>
                <CustomTooltip title="Average Quantity, Showing the Calculated Alignment / Backend Calculation ">
                  <span>Avg Qty</span>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <CustomTooltip title="Spec suggests that's these bins are being collected currently.">
                  <span>Qty</span>
                </CustomTooltip>
              </TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                border: "1px solid #F1F1F5",
              },
            }}
          >
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ mx: 2, display: "flex", gap: 1, alignItems: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Align Spec
        </Typography>
      </Box>
      <div className="mx-5">
        <BillingHistorySlider
          value={pickStartingFrom}
          maxValue={billingHistoryLength}
          onChange={setPickStartingFrom}
        />
      </div>
      <BillingHistoryChart
        hiddenServiceCodes={hiddenServiceCodes}
        billingHistory={slicedBillingHistory}
        cloneBillingHistory={cloneBillingHistory}
      />
    </>
  );
};

const CustomTooltip = ({ title, children, placement = "top", ...props }) => {
  const customStyles = {
    tooltip: {
      fontSize: "12px",
      background: "#000",
    },
    arrow: {
      color: "#000",
    },
  };

  return (
    <Tooltip
      title={title}
      placement={placement as TooltipProps["placement"]}
      arrow
      slotProps={{
        tooltip: { style: customStyles.tooltip },
        arrow: { style: customStyles.arrow },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const BillingHistorySlider = ({ maxValue, value, onChange }) => {
  const valueLabelFormat = (v) => {
    return `${maxValue - v}`;
  };

  const handleSliderChange = (_event: Event, newValue: number) => {
    onChange(newValue as number);
    //setLocalValue(newValue as number)
  };

  return (
    <Slider
      defaultValue={value}
      onChange={handleSliderChange}
      step={1}
      marks
      getAriaValueText={valueLabelFormat}
      valueLabelDisplay="on"
      valueLabelFormat={valueLabelFormat}
      min={0}
      max={maxValue - 1}
      track="inverted"
    />
  );
};
