import { useState } from "react";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import { Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import NotesSection from "./common/NotesSection";

const RequestTerminationForm = ({ form, context, errors, onSetForm }) => {
  const { finalCollectionRequired } = form;

  const { nextVisits, serviceName, visitPlanName } = context;

  const [open, setOpen] = useState(false);
  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Request Service Termination
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <CheckBoxWithLabel
          checked={finalCollectionRequired}
          onClick={(finalCollectionRequired) =>
            onSetForm({ finalCollectionRequired })
          }
        >
          Is Final Collection Required?
        </CheckBoxWithLabel>
        {finalCollectionRequired ? (
          <div>
            <label>Final Collection Required Before</label>
            <ServiceCalendarDatePicker
              // minDate={dayjs(new Date())}
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              onChange={(newValue) =>
                onSetForm({
                  finalCollectionRequiredBefore: newValue.format("DD/MM/YYYY"),
                })
              }
            />
          </div>
        ) : null}
        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who requested the termination?"
          contactLabel="Requested by"
          dateLabel="Requested on"
          dateValue={form?.requestedOn}
          onChange={(newValue) =>
            onSetForm({
              requestedOn: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>

      <Debugger {...form} />

      <ModelErrors errors={errors} />
    </div>
  );
};

RequestTerminationForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    finalCollectionRequired: PropTypes.bool.isRequired,
    finalCollectionRequiredBefore: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default RequestTerminationForm;
