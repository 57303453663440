import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import ContactSelect from "./ContactSelect";
import ServiceCalendarDatePicker from "./ServiceCalendarDatePicker";
import dayjs from "dayjs";

const NotesSection = ({
  form,
  onSetForm,
  context,
  contactLabel,
  contactPlaceholder,
  dateLabel,
  onChange,
  dateValue,
}) => {
  const { notes, sourceContactId } = form;

  const { sourceContacts, sourceIsUnknown } = context;
  const [openReportedOn, setOpenReportedOn] = useState(false);

  const disabled = !sourceIsUnknown;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        my: 1,
        mx: -2,
        p: "8px 16px",
        background: "#E2E2EA",

        "& .MuiTypography-root": {
          fontSize: "12px",
          fontWeight: "bold",
          minWidth: "85px",
        },
      }}
    >
      <Box
        flex="1"
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "8.5px 14px",
          },
        }}
      >
        <TextField
          placeholder="Notes"
          multiline
          fullWidth
          maxRows={2}
          value={notes}
          onChange={(e) =>
            onSetForm({
              notes: e.target.value,
            })
          }
          InputProps={{
            sx: {
              p: 0,
              fontSize: "12px",
              background: "#fff",
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "end",
          flex: 1,
        }}
      >
        {/* Contact label */}
        <Typography>{contactLabel}</Typography>
        <ContactSelect
          options={sourceContacts}
          selectedValue={sourceContactId}
          label={contactPlaceholder}
          disabled={disabled}
          onChange={({ sourceContactId, sourceStakeholderId }) =>
            onSetForm({
              sourceContactId: sourceContactId,
              sourceStakeholderId: sourceStakeholderId,
            })
          }
        />
        {/* Date label */}
        <Typography>{dateLabel}</Typography>
        <Box>
          <ServiceCalendarDatePicker
            open={openReportedOn}
            setOpen={setOpenReportedOn}
            defaultValue={dateValue ? dayjs(dateValue) : null}
            disabled={disabled}
            maxDate={dayjs()}
            onChange={onChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NotesSection;
